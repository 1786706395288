import type * as entity from '@boutique/entities'
import * as logger from '@owl-nest/logger'
// Do not change this export to `@owl-nest/utils` because some other utils
// depends on the design system. We don't expect `contentful-shopify-matcher` to
// need jsx transformation to be executed
import * as textUtils from '@owl-nest/utils/src/text.ts'
import type { SlimCold } from '@owl-nest/cold-data/client.ts'

export function matchBrand(
  identifier: string,
  brand: { identifier?: string | undefined | null; name: string },
): boolean {
  /* find matching brand with identifier or name as fallback since identifiers aren't all filled yet */
  return textUtils.slugify(brand.identifier ?? brand.name, '-') === textUtils.slugify(identifier, '-')
}

export function getMatchingBrand(vendor: string, cold: { brands: SlimCold['brands'] }): entity.brand.SlimColdBrand {
  // first try exact match with `brand.identifier`
  const identifierMatch = Object.values(cold.brands).find((brand) =>
    matchBrand(vendor, {
      identifier: brand.identifier,
      name: brand.name,
    }),
  )

  if (identifierMatch !== undefined) {
    // NOTE: To account for brands that match but have missing/wrong identifiers
    //  Contentful-side, we manually set it to the Shopify vendor.
    identifierMatch.identifier = vendor
    return identifierMatch
  }

  const vendorSlug = textUtils.slugify(vendor, '-')

  // no exact match found, fallback on approximate match with `brand.slug`
  const slugMatch = Object.values(cold.brands).find((brand) => brand.slug === vendorSlug)

  if (slugMatch !== undefined) {
    return { ...slugMatch, identifier: vendor }
  }

  const errorMessage = `no brand matching given vendor ${vendorSlug} found`
  logger.err(`[matcher] ${textUtils.capitalize(errorMessage)}`)
  throw Error(errorMessage)
}
