import * as shopify from '@owl-nest/shopify'
import * as entity from '@boutique/entities'
import * as cold from '@owl-nest/cold-data/client'

export async function get(checkoutId: string): Promise<entity.checkout.Checkout | null> {
  try {
    const checkout = await shopify.checkout.get(checkoutId)
    if (checkout === null) {
      return null
    }
    return entity.checkout.checkout(checkout, cold)
  } catch {
    return null
  }
}

export async function create(
  lineItems: entity.checkout.PartialLineItem[],
  customAttributes?: entity.checkout.CustomAttribute[],
): Promise<entity.checkout.Checkout> {
  const { checkout, checkoutUserErrors } = await shopify.checkout.create({
    lineItems,
    customAttributes,
  })

  if (checkoutUserErrors.length > 0) {
    throw checkoutUserErrors as entity.checkout.CheckoutError[]
  }

  return entity.checkout.checkout(checkout, cold)
}

export async function addLineItems(
  checkoutId: string,
  lineItems: entity.checkout.PartialLineItem[],
): Promise<entity.checkout.Checkout> {
  const { checkout, checkoutUserErrors } = await shopify.checkout.addLineItems(checkoutId, lineItems)

  if (checkoutUserErrors.length > 0) {
    throw checkoutUserErrors as entity.checkout.CheckoutError[]
  }

  return entity.checkout.checkout(checkout, cold)
}

export async function updateLineItems(
  checkoutId: string,
  lineItems: entity.checkout.LineItem[],
): Promise<entity.checkout.Checkout> {
  const { checkout, checkoutUserErrors } = await shopify.checkout.updateLineItems(
    checkoutId,
    lineItems.map((lineItem) => ({
      id: lineItem.id,
      quantity: lineItem.quantity,
      variantId: lineItem.variant.id,
    })),
  )

  if (checkoutUserErrors.length > 0) {
    throw checkoutUserErrors as entity.checkout.CheckoutError[]
  }

  return entity.checkout.checkout(checkout, cold)
}

export async function removeLineItems(
  checkoutId: string,
  lineItems: { id: string }[],
): Promise<entity.checkout.Checkout> {
  const { checkout, checkoutUserErrors } = await shopify.checkout.removeLineItems(
    checkoutId,
    lineItems.map((lineItem) => lineItem.id),
  )

  if (checkoutUserErrors.length > 0) {
    throw checkoutUserErrors as entity.checkout.CheckoutError[]
  }

  return entity.checkout.checkout(checkout, cold)
}

export async function updateAttributes(
  checkoutId: string,
  attributes: { key: string; value: string }[],
): Promise<entity.checkout.Checkout> {
  const { checkout, checkoutUserErrors } = await shopify.checkout.attributesUpdate(checkoutId, attributes)

  if (checkoutUserErrors.length > 0) {
    throw checkoutUserErrors as entity.checkout.CheckoutError[]
  }

  return entity.checkout.checkout(checkout, cold)
}

export async function applyDiscountCodeToCheckout(
  checkoutId: string,
  discountCode: string,
): Promise<entity.checkout.Checkout> {
  const { checkout, checkoutUserErrors } = await shopify.checkout.applyDiscountCodeToCheckout(checkoutId, discountCode)

  if (checkoutUserErrors.length > 0) {
    throw checkoutUserErrors as entity.checkout.CheckoutError[]
  }

  return entity.checkout.checkout(checkout, cold)
}
