import styled from 'styled-components'

import * as picture from './picture'
import * as breakpoint from '../constants/breakpoints'
import * as size from '../constants/sizes'

export const List = styled.ul`
  align-items: center;
  display: flex;
  overflow: auto;
`

export const Li = styled.li`
  display: inline-flex;
  width: 18%;
  flex: none;

  margin: 0px 2.2%;

  &:first-child {
    margin: 0px 2.2% 0px 0px;
  }
  &:last-child {
    margin: 0px 0px 0px 2.2%;
  }
`

export const Thumbnail = styled.div`
  height: 0px;
  position: relative;
  padding-bottom: 133%;
  width: 100%;
  cursor: pointer;

  ${picture.Picture} {
    position: absolute;
    top: 0;
    bottom: 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 100%;

  @media screen and ${breakpoint.LAPTOP} {
    height: calc(100vh - 53px);
    padding-top: 20px;
  }

  & > ${picture.ZommablePicture} {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  ${List} {
    flex: 0 0 auto;
    margin-top: 10px;

    @media screen and ${breakpoint.TABLET} {
      margin-top: 20px;
    }
  }
`
