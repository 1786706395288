import * as api from '@owl-nest/api-client/next'

import * as entity from '@boutique/entities'

export async function getShippingRates(
  country: string,
  items: {
    quantity: number
    shopifyVariantId: number
  }[],
  vendors: string[],
): Promise<api.HttpResult<entity.shipping.ShippingRateData>> {
  const response = await api.client('POST /shipping-rates', {
    body: {
      country,
      items: items.map((item) => ({
        quantity: item.quantity,
        shopify_variant_id: item.shopifyVariantId,
      })),
    },
  })

  return response.next((response) => {
    return entity.shipping.shippingRateData(response, vendors)
  })
}
