import * as React from 'react'
import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'
import NextLink from 'next/link'

import * as hooks from '@owl-nest/hooks'
import * as cold from '@owl-nest/cold-data/client'
import { Tree } from '@owl-nest/cold-data/hierarchy'
import * as plume from '@ulule/owl-kit-components/next'
import { subnavStyles } from '@ulule/header'
import { useHierarchyHref } from '@boutique/hooks'

import { MenuItem, TopLevelEntry, Underlined } from './styles'

import { XmasIcon } from '../XmasIcon'

type CategoriesEntriesProps = {
  forceCategory: string | undefined
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function CategoriesEntries({
  forceCategory,
  isDrawerOpen,
  setIsDrawerOpen,
}: CategoriesEntriesProps): React.ReactElement {
  return (
    <CategoriesList>
      {cold.hierarchy.tree.children.map((child, index) => (
        <Category
          key={`category-${index}`}
          child={child}
          forceCategory={forceCategory}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      ))}
      <GiftIdeasMenuItem />
      <plume.styles.modal.Overlay />
    </CategoriesList>
  )
}

function Category({
  child,
  forceCategory,
  isDrawerOpen,
  setIsDrawerOpen,
}: {
  child: Tree
  forceCategory: string | undefined
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}): React.ReactElement {
  const router = useRouter()

  const [productTypeSize, setProductTypeSize] = React.useState({ width: 0, height: 0 })
  const [categoryContentSize, setCategoryContentSize] = React.useState({ width: 0, height: 0 })
  const categoryContentRef$ = React.useRef<HTMLUListElement>(null)
  const dropdownWrapperRef = React.useRef<HTMLDivElement>(null)

  hooks.useDramaticallyInefficientScrollListener(() => {
    if (categoryContentRef$.current) {
      const rect = categoryContentRef$.current.getBoundingClientRect()
      setCategoryContentSize(({ width, height }) => ({
        width: Math.max(width, rect.width),
        height: Math.max(height, rect.height),
      }))
    }
  })

  const categoryHref = useHierarchyHref(child.id)

  const height = Math.max(productTypeSize.height, categoryContentSize.height)

  const [isCategoryContentListHover, setIsCategoryContentListHover] = React.useState(false)

  return (
    <CategoryMenuItem key={child.id} enableOpenHover={!isDrawerOpen}>
      <StyledNextLink href={categoryHref}>
        <TopLevelEntry
          as="div"
          $active={isCategoryActive(child)}
          // HACK: The opening/closing of the desktop drawer is not controlled by a useState but through a hover css property.
          // When clicking on this element, we expect the drawer to close, but the mouse is still hovering over the element, so it doesn't close.
          // To resolve this issue, we condition the application of hover styles with a boolean
          // and the drawer closes.
          onClick={async () => {
            setIsDrawerOpen(true)
            await new Promise((resolve) => setTimeout(resolve, 50))
          }}
          onMouseLeave={() => setIsDrawerOpen(false)}
        >
          <Underlined>{child.name}</Underlined>
        </TopLevelEntry>
      </StyledNextLink>
      <DropdownWrapper ref={dropdownWrapperRef}>
        <CategoryFloatingWrapper $width={productTypeSize.width + categoryContentSize.width} $height={height}>
          <CategoryContentList
            ref={categoryContentRef$}
            onMouseEnter={() => setIsCategoryContentListHover(true)}
            onMouseLeave={() => setIsCategoryContentListHover(false)}
          >
            {child.children.map((child, index, array) => (
              <CategoryContent
                key={index}
                array={array}
                child={child}
                height={height}
                index={index}
                isCategoryContentListHover={isCategoryContentListHover}
                setProductTypeSize={setProductTypeSize}
                // HACK: The opening/closing of the desktop drawer is not controlled by a useState but through a hover css property.
                // When clicking on this element, we expect the drawer to close, but the mouse is still hovering over the drawer, so it doesn't close.
                // To resolve this issue, we condition the application of hover styles with a boolean
                // and the drawer closes.
                closeDrawer={async () => {
                  setIsDrawerOpen(true)
                  await new Promise((resolve) => setTimeout(resolve, 50))
                  setIsDrawerOpen(false)
                }}
              />
            ))}
          </CategoryContentList>
          <SeeAllCategoryItem>
            <SeeAllLink href={categoryHref}>
              <plume.styles.copy.S as="span">Voir tous les produits {child.name}</plume.styles.copy.S>
              <plume.glyphs.stroke.ArrowRight size={16} />
            </SeeAllLink>
          </SeeAllCategoryItem>
        </CategoryFloatingWrapper>
      </DropdownWrapper>
    </CategoryMenuItem>
  )

  function isCategoryActive(category: cold.SlimCold['hierarchy']['tree']): boolean {
    return (
      router.query['category'] === category.id ||
      cold.getTree(cold.hierarchy, router.query['hierarchy'] as string).parents[0]?.id === category.id ||
      (forceCategory !== undefined && cold.tags[forceCategory].parents.includes(category.id))
    )
  }
}

const StyledNextLink = styled(NextLink)`
  height: 100%;
  width: 100%;
`

type CategoryContentProps = {
  array: Tree[]
  child: Tree
  height: number
  index: number
  isCategoryContentListHover: boolean
  closeDrawer: () => void
  setProductTypeSize: React.Dispatch<
    React.SetStateAction<{
      width: number
      height: number
    }>
  >
}

function CategoryContent({
  array,
  child,
  height,
  index,
  isCategoryContentListHover,
  closeDrawer,
  setProductTypeSize,
}: CategoryContentProps): React.ReactElement {
  const router = useRouter()

  const productTypePanelRef$ = React.useRef<HTMLDivElement>(null)

  hooks.useDramaticallyInefficientScrollListener(() => {
    if (productTypePanelRef$.current) {
      const rect = productTypePanelRef$.current.getBoundingClientRect()
      setProductTypeSize(({ width, height }) => ({
        width: Math.max(width, rect.width),
        height: Math.max(height, rect.height),
      }))
    }
  })

  plume.hooks.useIsoLayoutEffect(() => {
    if (productTypePanelRef$.current && height !== 0) {
      productTypePanelRef$.current.style.height = `${height}px`
    }
  }, [height])

  const subcategoryHref = useHierarchyHref(child.id)

  const activeCategoryTree = cold.getTree(cold.hierarchy, router.query['hierarchy'] as string)

  const hasActiveSibling = child.parents
    .map((parent) => parent.id)
    .some((value) => activeCategoryTree.parents.map((parent) => parent.id).includes(value))

  // Note: The highlighting of elements is not controlled by a useState but through both hover css properties and the $active jsx prop.
  // We're forcing the highlighting of the first element of the list when nothing is active and no other element is hovered through a first-child css selector.
  // When the user hovers over an element and the element has no active sibling, we cancel the highlighting of the first element.
  const isListNotHover = !hasActiveSibling && !isCategoryContentListHover

  return (
    <SubCategoryItem
      key={child.id}
      $active={isCategoryActive(child)}
      $last={index === array.length - 1}
      isListNotHover={isListNotHover}
    >
      <NextLink href={subcategoryHref}>
        <SubCategoryLink as="div" onClick={closeDrawer}>
          <plume.styles.copy.S>{child.name}</plume.styles.copy.S>
          <plume.glyphs.stroke.CaretRightLightweight size={16} />
        </SubCategoryLink>
      </NextLink>
      <ProductTypePanel ref={productTypePanelRef$}>
        <ProductTypeList $length={child.children.length}>
          {child.children.map((child) => {
            const href = useHierarchyHref(child.id)

            return (
              <ProductTypeItem key={child.id}>
                <plume.styles.copy.S as="div">
                  <NextLink href={href}>
                    <plume.styles.link.Primary as="div" onClick={closeDrawer}>
                      {child.name}
                    </plume.styles.link.Primary>
                  </NextLink>
                </plume.styles.copy.S>
              </ProductTypeItem>
            )
          })}
        </ProductTypeList>
        <SeeAllLink href={subcategoryHref}>
          <plume.styles.copy.S as="span">Voir tous les produits {child.name}</plume.styles.copy.S>
          <plume.glyphs.stroke.ArrowRight size={16} />
        </SeeAllLink>
      </ProductTypePanel>
    </SubCategoryItem>
  )

  function isCategoryActive(categoryTree: cold.SlimCold['hierarchy']['tree']): boolean {
    return (
      activeCategoryTree?.id === categoryTree.id ||
      activeCategoryTree.parents.map((parent) => parent.id).includes(categoryTree?.id)
    )
  }
}

function GiftIdeasMenuItem(): React.ReactElement {
  const router = useRouter()

  return (
    <MenuItem>
      <NextLink href="/idees-cadeaux">
        <TopLevelEntry $active={router.pathname === '/gift-ideas'} as="div">
          <CenterSpan>
            <XmasIcon /> Idées cadeaux
          </CenterSpan>
        </TopLevelEntry>
      </NextLink>
    </MenuItem>
  )
}

const SeeAllLink = styled(NextLink)`
  width: 100%;
  display: flex;
  align-items: center;

  ${plume.styles.copy.S} {
    font-weight: 600;
    margin-right: 8px;
  }

  &:hover ${plume.styles.copy.S} {
    text-decoration: underline;
  }
`

const CenterSpan = styled.span`
  align-items: center;
  background-color: #d9f8f6; /* HACK: Replace with COLORS.SECONDARY_MINT_300 */
  border-radius: 2px;
  color: #2e8d87; /* HACK: Replace with COLORS.SECONDARY_MINT_700 */
  display: flex;
  height: 26px;
  padding: 4px 8px;
  position: relative;
  top: -0.5px;

  ${XmasIcon} {
    margin-right: 4px;
  }
`

const CategoriesList = styled(subnavStyles.SubNavItemList)`
  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    position: relative;
  }
`

const CategoryContentList = styled.ul`
  background: ${plume.COLORS.PRIMARY_GREY_000};
  border-right: 1px solid ${plume.COLORS.PRIMARY_SAND_200};
  display: flex;
  flex-direction: column;
  flex: 1;
`

const CategoryFloatingWrapper = styled.div<{ $width: number; $height: number }>`
  position: relative;
  width: 256px;
  min-height: ${({ $height }) => $height}px;
  display: flex;
  flex-direction: column;
`

const ProductTypePanel = styled.div`
  position: absolute;
  left: calc(100% - 1px);
  top: 0;
  background-color: ${plume.COLORS.PRIMARY_GREY_000};
  width: calc(100vw - 256px);
  max-width: 1174px;
  padding: 24px 56px 48px;
  border-left: 1px solid ${plume.COLORS.PRIMARY_SAND_200};

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    width: calc(100vw - 266px - 256px);
  }
`

const ProductTypeList = styled.ul<{ $length: number }>`
  display: grid;
  grid-template-columns: minmax(140px, max-content) minmax(140px, max-content) minmax(140px, max-content);
  grid-template-rows: repeat(${({ $length }) => Math.ceil($length / 3)}, 1fr);
  grid-auto-flow: column;
  grid-gap: 0 32px;
  margin-bottom: 40px;

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    grid-gap: 0 70px;
  }
`

const ProductTypeItem = styled.li`
  padding: 5px;
`

const SubCategoryLink = styled(plume.Link)`
  border: none;
  padding: 10px 15px 10px 24px;
  width: 256px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${plume.glyphs.stroke.CaretRightLightweight} {
    color: ${plume.COLORS.PRIMARY_SAND_500};
  }
`

const SubCategoryItem = styled.li<{
  $active?: boolean
  $last?: boolean
  isListNotHover: boolean
}>`
  ${({ $last }) => {
    if ($last) {
      return css`
        margin-bottom: 24px;
      `
    }
  }}

  ${ProductTypePanel} {
    z-index: -2;
  }
  &:first-child {
    ${ProductTypePanel} {
      z-index: -1;
    }

    ${({ isListNotHover }) => {
      if (isListNotHover) {
        return css`
          ${SubCategoryLink} {
            background: ${plume.COLORS.PRIMARY_BLUE_100};
            ${plume.styles.copy.S} {
              font-weight: 600;
            }

            ${plume.glyphs.stroke.CaretRightLightweight} {
              color: ${plume.COLORS.PRIMARY_GREY_900};
            }
          }
        `
      }
    }}
  }

  ${({ $active }) => {
    if ($active) {
      return css`
        ${SubCategoryLink} {
          background: ${plume.COLORS.PRIMARY_BLUE_100};

          ${plume.styles.copy.S} {
            font-weight: 600;
          }

          ${plume.glyphs.stroke.CaretRightLightweight} {
            color: ${plume.COLORS.PRIMARY_GREY_900};
          }
        }
        ${ProductTypePanel} {
          z-index: 1;
        }
      `
    } else {
      return css`
        &:hover {
          ${SubCategoryLink} {
            background: ${plume.COLORS.PRIMARY_BLUE_100};

            ${plume.styles.copy.S} {
              font-weight: 600;
            }

            ${plume.glyphs.stroke.CaretRightLightweight} {
              color: ${plume.COLORS.PRIMARY_GREY_900};
            }
          }
          ${ProductTypePanel} {
            z-index: 2;
          }
        }
      `
    }
  }}
`

const SeeAllCategoryItem = styled.div`
  border-top: 1px solid ${plume.COLORS.PRIMARY_SAND_200};
  border-right: 1px solid ${plume.COLORS.PRIMARY_SAND_200};
  margin-top: auto;
  padding: 16px 24px 16px 16px;
`

const DropdownWrapper = styled.div`
  background: ${plume.COLORS.PRIMARY_GREY_000};
  position: absolute;
  left: 0;
  top: calc(100% + 6px);
  width: 100vw;
  z-index: -1;

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    width: calc(100vw - 266px);
  }
`

const CategoryMenuItem = styled(MenuItem)<{ enableOpenHover: boolean }>`
  & ~ ${plume.styles.modal.Overlay} {
    opacity: 0;
    pointer-events: none;
    z-index: -2;
  }

  ${DropdownWrapper} {
    height: 0;
    overflow: hidden;
  }

  ${({ enableOpenHover }) => {
    if (enableOpenHover === true) {
      return css`
        &:hover {
          & ~ ${plume.styles.modal.Overlay} {
            opacity: 1;
            pointer-events: all;
          }

          ${DropdownWrapper} {
            overflow: visible;
            height: auto;
          }

          ${TopLevelEntry} {
            ${Underlined}::after {
              left: 0;
              right: 0;
              opacity: 1;
            }
          }
        }
      `
    }
  }}
`
