import * as React from 'react'
import styled from 'styled-components'

import * as glyphs from '../../icons/glyphs'
import * as copy from '../../styles/copy'
import * as notification from '../../styles/notification'

import { Base, type BaseProps } from './Base'

export type BannerProps = BaseProps & {
  closable?: boolean
  onClose?: () => void
  wrapWithCopy?: boolean
}

function BannerComponent({
  children,
  className,
  closable = false,
  customIcon,
  onClose,
  type,
  wrapWithCopy = true,
}: BannerProps): React.ReactElement<BannerProps> | null {
  const [isBannerOpen, setIsBannerOpen] = React.useState(true)

  if (!isBannerOpen) {
    return null
  }

  return (
    <Base className={className} customIcon={customIcon} type={type}>
      {wrapWithCopy ? <copy.S>{children}</copy.S> : children}
      <notification.BannerCloseButton
        visible={closable}
        onClick={() => {
          onClose?.()
          setIsBannerOpen(false)
        }}
      >
        <glyphs.stroke.Close size={16} />
      </notification.BannerCloseButton>
    </Base>
  )
}

export const Banner = styled(BannerComponent)<BannerProps>`
  justify-content: space-between;
  text-align: center;
`
Banner.displayName = 'Banner'
