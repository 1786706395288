import * as React from 'react'

export type ExtraOption = {
  action?: {
    label: string
  }
  message: string
}

export type Level = 'alert' | 'error' | 'info' | 'success'

export type Message = {
  context?: string
  extra?: ExtraOption
  level: Level
  message: string | React.ReactNode
}

export type MessageState = Message[]

export const MESSAGE = 'MESSAGE'
export const CLEAR = 'CLEAR'
export const SYNC = 'MESSAGE.SYNC'

export type CreateActionOptions = {
  context?: string
  extra?: ExtraOption
  focus?: boolean
}

export type CreateAction = {
  type: typeof MESSAGE
  level: Level
  message: string
  options: CreateActionOptions
}

export type ClearAction = {
  type: typeof CLEAR
  context?: string
}

export type SyncAction = {
  type: typeof SYNC
  state: MessageState
}

export type MessageAction = ClearAction | CreateAction | SyncAction
