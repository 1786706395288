import { fragment, query } from '@owl-nest/graphql'

import * as shopify from './base.ts'
import { request } from './utils/requester.ts'

type ResponsiveImage = shopify.Image & {
  transformedSrc1x: string | null
  transformedSrc2x: string | null
  transformedSrc3x: string | null
}

export type CheckoutLineItem = {
  id: string
  quantity: number
  discountAllocations: {
    allocatedAmount: shopify.Money
  }[]
  customAttributes: { key: string; value: string }[]
  variant: {
    id: string
    title: string
    compareAtPriceV2: shopify.Money | null
    priceV2: shopify.Money
    image: ResponsiveImage
    quantityAvailable: number
    product: {
      deliveryExtra: {
        value: string | null
      } | null
      id: string
      handle: string
      productionDays: {
        value: number | null
      } | null
      tags: string[]
      title: string
      vendor: string
    }
  }
}

const CHECKOUT_LINE_ITEM_FRAGMENT = fragment`
    fragment BaseCheckoutLineItem on CheckoutLineItem {
        id
        discountAllocations {
            allocatedAmount {
                ...${shopify.MONEY_FRAGMENT}
            }
        }
        customAttributes {
            key
            value
        }
        quantity
        variant {
            id
            title
            priceV2 {
                ...${shopify.MONEY_FRAGMENT}
            }
            compareAtPriceV2 {
                ...${shopify.MONEY_FRAGMENT}
            }
            image {
                ...${shopify.IMAGE_FRAGMENT}
                transformedSrc1x: url(transform: { maxWidth: 120, maxHeight: 120, scale: 1 })
                transformedSrc2x: url(transform: { maxWidth: 120, maxHeight: 120, scale: 2 })
                transformedSrc3x: url(transform: { maxWidth: 120, maxHeight: 120, scale: 2 })
            }
            quantityAvailable
            product {
                deliveryExtra: metafield(namespace: "front", key: "delivery_extra") {
                    value
                }
                id
                handle
                productionDays: metafield(namespace: "api", key: "production_days") {
                    value
                }
                tags
                title
                vendor
            }
        }
    }
`

export type Checkout = {
  completedAt: string
  id: string
  webUrl: string
  customAttributes: { key: string; value: string }[]
  discountApplications: {
    edges: {
      node:
        | {
            __typename: 'AutomaticDiscountApplication'
            title: string
          }
        | {
            __typename: 'DiscountCodeApplication'
          }
        | {
            __typename: 'ManualDiscountApplication'
          }
        | {
            __typename: 'ScriptDiscountApplication'
          }
    }[]
  }
  lineItems: {
    edges: {
      node: CheckoutLineItem
    }[]
  }
}

const CHECKOUT_FRAGMENT = fragment`
    fragment BaseCheckout on Checkout {
        completedAt
        webUrl
        id
        customAttributes {
            key
            value
        }
        discountApplications(first: 5) {
            edges {
                node {
                    __typename
                    ... on AutomaticDiscountApplication {
                        title
                    }
                }
            }
        }
        lineItems(first: 15) {
            edges {
                node {
                    ...${CHECKOUT_LINE_ITEM_FRAGMENT}
                }
            }
        }
    }
`

export type CheckoutError = {
  code: string
  field: string
  message: string
}

const CHECKOUT_ERROR_FRAGMENT = fragment`
    fragment BaseCheckoutError on CheckoutUserError {
        code
        field
        message
    }
`

type CreationInput = {
  input: {
    lineItems?: shopify.LineItem[]
    allowPartialAddresses?: boolean
    shippingAddress?: shopify.MailingAddressInput
    customAttributes?: { key: string; value: string }[]
  }
}

type CreationMutationResult = {
  checkoutCreate: {
    checkout: Checkout
    checkoutUserErrors: CheckoutError[]
  }
}

const CREATION_MUTATION = query<CreationMutationResult, CreationInput>`
    mutation checkoutCreate($input: CheckoutCreateInput!) {
        checkoutCreate(input: $input) {
            checkout {
                ...${CHECKOUT_FRAGMENT}
            }
            checkoutUserErrors {
                ...${CHECKOUT_ERROR_FRAGMENT}
            }
        }
    }
`

type QueryInput = {
  checkoutId: Checkout['id']
}

type QueryResult = { node: Checkout }

const CHECKOUT_QUERY = query<QueryResult, QueryInput>`
    query($checkoutId: ID!) {
        node(id: $checkoutId) {
            ... on Checkout {
                ...${CHECKOUT_FRAGMENT}
            }
        }
    }
`

type AddItemMutationInput = {
  lineItems: shopify.LineItem[]
  checkoutId: Checkout['id']
}

type AddItemMutationResult = {
  checkoutLineItemsAdd: {
    checkout: Checkout
    checkoutUserErrors: CheckoutError[]
  }
}

const ADD_ITEM_MUTATION = query<AddItemMutationResult, AddItemMutationInput>`
    mutation checkoutLineItemsAdd(
        $lineItems: [CheckoutLineItemInput!]!
        $checkoutId: ID!
    ) {
        checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
            checkout {
                ...${CHECKOUT_FRAGMENT}
            }
            checkoutUserErrors {
                ...${CHECKOUT_ERROR_FRAGMENT}
            }
        }
    }
`

type UpdateItemMutationInput = {
  lineItems: (shopify.LineItem & { id: string })[]
  checkoutId: Checkout['id']
}

type UpdateItemMutationResult = {
  checkoutLineItemsUpdate: {
    checkout: Checkout
    checkoutUserErrors: CheckoutError[]
  }
}

const UPDATE_ITEM_MUTATION = query<UpdateItemMutationResult, UpdateItemMutationInput>`
    mutation checkoutLineItemsUpdate(
        $lineItems: [CheckoutLineItemUpdateInput!]!
        $checkoutId: ID!
    ) {
        checkoutLineItemsUpdate(lineItems: $lineItems, checkoutId: $checkoutId) {
            checkout {
                ...${CHECKOUT_FRAGMENT}
            }
            checkoutUserErrors {
                ...${CHECKOUT_ERROR_FRAGMENT}
            }
        }
    }
`

type RemoveItemMutationInput = {
  lineItemIds: string[]
  checkoutId: Checkout['id']
}

type RemoveItemMutationResult = {
  checkoutLineItemsRemove: {
    checkout: Checkout
    checkoutUserErrors: CheckoutError[]
  }
}

const REMOVE_ITEM_MUTATION = query<RemoveItemMutationResult, RemoveItemMutationInput>`
    mutation checkoutLineItemsRemove(
        $lineItemIds: [ID!]!
        $checkoutId: ID!
    ) {
        checkoutLineItemsRemove(lineItemIds: $lineItemIds, checkoutId: $checkoutId) {
            checkout {
                ...${CHECKOUT_FRAGMENT}
            }
            checkoutUserErrors {
                ...${CHECKOUT_ERROR_FRAGMENT}
            }
        }
    }
`

export async function create(input: CreationInput['input']): Promise<CreationMutationResult['checkoutCreate']> {
  const response = (
    await request(CREATION_MUTATION, {
      input,
    })
  ).doThrow()

  return response.checkoutCreate
}

export async function get(checkoutId: string): Promise<Checkout | null> {
  const response = (
    await request<{ node: Checkout | null }>(CHECKOUT_QUERY, {
      checkoutId,
    })
  ).doThrow()

  return response?.node
}

export async function addLineItems(
  checkoutId: AddItemMutationInput['checkoutId'],
  lineItems: AddItemMutationInput['lineItems'],
): Promise<AddItemMutationResult['checkoutLineItemsAdd']> {
  const response = (
    await request(ADD_ITEM_MUTATION, {
      lineItems,
      checkoutId,
    })
  ).doThrow()

  return response.checkoutLineItemsAdd
}

export async function updateLineItems(
  checkoutId: UpdateItemMutationInput['checkoutId'],
  lineItems: UpdateItemMutationInput['lineItems'],
): Promise<UpdateItemMutationResult['checkoutLineItemsUpdate']> {
  const response = (
    await request(UPDATE_ITEM_MUTATION, {
      lineItems,
      checkoutId,
    })
  ).doThrow()

  return response.checkoutLineItemsUpdate
}

export async function removeLineItems(
  checkoutId: RemoveItemMutationInput['checkoutId'],
  lineItemIds: RemoveItemMutationInput['lineItemIds'],
): Promise<RemoveItemMutationResult['checkoutLineItemsRemove']> {
  const response = (
    await request(REMOVE_ITEM_MUTATION, {
      lineItemIds,
      checkoutId,
    })
  ).doThrow()

  return response.checkoutLineItemsRemove
}

type AttributesUpdateMutationInput = {
  checkoutId: Checkout['id']
  input: {
    customAttributes: { key: string; value: string }[]
  }
}

type AttributesUpdateMutationResult = {
  checkoutAttributesUpdateV2: {
    checkout: Checkout
    checkoutUserErrors: CheckoutError[]
  }
}

const ATTRIBUTES_UPDATE_MUTATION = query<AttributesUpdateMutationResult, AttributesUpdateMutationInput>`
    mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
        checkoutAttributesUpdateV2(
            checkoutId: $checkoutId,
            input: $input
        ) {
            checkout {
                ...${CHECKOUT_FRAGMENT}
            }
            checkoutUserErrors {
                ...${CHECKOUT_ERROR_FRAGMENT}
            }
        }
    }
`

export async function attributesUpdate(
  checkoutId: AttributesUpdateMutationInput['checkoutId'],
  customAttributes: AttributesUpdateMutationInput['input']['customAttributes'],
): Promise<AttributesUpdateMutationResult['checkoutAttributesUpdateV2']> {
  const response = (
    await request(ATTRIBUTES_UPDATE_MUTATION, {
      checkoutId,
      input: {
        customAttributes,
      },
    })
  ).doThrow()

  return response.checkoutAttributesUpdateV2
}

type ApplyDiscountCodeToCheckoutMutationInput = {
  checkoutId: Checkout['id']
  discountCode: string
}

type ApplyDiscountCodeToCheckoutMutationResult = {
  checkoutDiscountCodeApplyV2: {
    checkout: Checkout
    checkoutUserErrors: CheckoutError[]
  }
}

const APPLY_DISCOUNT_CODE_TO_CHECKOUT_MUTATION = query<
  ApplyDiscountCodeToCheckoutMutationResult,
  ApplyDiscountCodeToCheckoutMutationInput
>`
mutation applyDiscountCodeToCheckout($checkoutId: ID!, $discountCode: String!) {
  checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
    checkout {
        ...${CHECKOUT_FRAGMENT}
    }
    checkoutUserErrors {
        ...${CHECKOUT_ERROR_FRAGMENT}
    }
  }
}
`

export async function applyDiscountCodeToCheckout(
  checkoutId: ApplyDiscountCodeToCheckoutMutationInput['checkoutId'],
  discountCode: ApplyDiscountCodeToCheckoutMutationInput['discountCode'],
): Promise<ApplyDiscountCodeToCheckoutMutationResult['checkoutDiscountCodeApplyV2']> {
  const response = (
    await request(APPLY_DISCOUNT_CODE_TO_CHECKOUT_MUTATION, {
      checkoutId,
      discountCode,
    })
  ).doThrow()

  return response.checkoutDiscountCodeApplyV2
}
