import { useRouter, type Router } from 'next/router'

type ApplyOptions = { shallow?: boolean; scroll?: boolean; type?: 'push' | 'replace' }

export type UseQueryParams<QUERY> = {
  query: QUERY
  apply: (query: QUERY, options?: { shallow?: boolean; scroll?: boolean }) => void
  href: (query: QUERY) => string
}

export function useQueryParams<QUERY>(
  pathname: string,
  fromParsedUrlQuery: (query: Router['query']) => QUERY,
  toUrlSearchParams: (query: QUERY) => URLSearchParams,
): UseQueryParams<QUERY> {
  const router = useRouter()
  const query = fromParsedUrlQuery(router.query)

  return { query, apply, href }

  function apply(query: QUERY, { scroll = true, shallow = true, type = 'push' }: ApplyOptions = {}): void {
    router[type](href(query), undefined, { scroll, shallow })
  }

  function href(query: QUERY): string {
    const searchParams = toUrlSearchParams(query).toString()
    return `${pathname}${searchParams ? `?${searchParams}` : ''}`
  }
}
