import * as React from 'react'
import styled from 'styled-components'

import * as icons from '../icons/glyphs'
import * as S from '../styles'

export type QuantityInputProps = {
  quantity: number
  disabled?: boolean
  onIncrement?: () => void
  onDecrement?: () => void
  onRemove?: () => void
}

function QuantityInputComponent({
  quantity,
  disabled,
  onIncrement,
  onDecrement,
  onRemove,
}: QuantityInputProps): React.ReactElement {
  return (
    <S.quantityInput.QuantityWrapper>
      <S.quantityInput.Controls>
        <S.quantityInput.ButtonQuantity
          disabled={disabled || onDecrement === undefined}
          aria-label="-"
          onClick={onDecrement}
        >
          <icons.stroke.Minus size={16} />
        </S.quantityInput.ButtonQuantity>
        <S.quantityInput.Quantity>{quantity}</S.quantityInput.Quantity>
        <S.quantityInput.ButtonQuantity
          disabled={disabled || onIncrement === undefined}
          aria-label="+"
          onClick={onIncrement}
        >
          <icons.stroke.Plus size={16} />
        </S.quantityInput.ButtonQuantity>
        <S.quantityInput.UnstyledButton
          disabled={disabled || onRemove === undefined}
          aria-label="supprimer"
          onClick={onRemove}
        >
          <icons.stroke.Trash size={16} />
        </S.quantityInput.UnstyledButton>
      </S.quantityInput.Controls>
    </S.quantityInput.QuantityWrapper>
  )
}

export const QuantityInput = styled(QuantityInputComponent)``
