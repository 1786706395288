import styled from 'styled-components'

import * as BREAKPOINT from '../../constants/breakpoints'
import * as colors from '../../constants/colors'
import * as heading from '../heading'

export const Picture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${colors.GREY_SHADE_5};

  @media screen and ${BREAKPOINT.TABLET} {
    width: 60px;
    height: 60px;
  }
`
export const Title = styled(heading.XS)`
  color: ${colors.PRIMARY_BLACK};
  flex-basis: 100%;
  font-weight: 500;
  padding-left: 8px;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 584px;
  padding-bottom: 20px;

  // small class to simulate mobile mediaquery
  // TODO: FIXME: Use prop instead, when integrating real-world components
  &.small {
    max-width: 355px;
  }
`
