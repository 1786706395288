import styled, { css } from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'
import { styles } from '@ulule/header'

export const Underlined = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;

  &::after {
    content: '';
    background-color: #2e8d87; /* HACK: Replace with COLORS.SECONDARY_MINT_700 */
    bottom: -10px;
    height: 6px;
    left: 50%;
    right: 50%;
    position: absolute;
  }
`

export const TopLevelEntry = styled(styles.LinkItem)<{ highlight?: boolean }>`
  position: relative;
  border: none;
  height: 100%;

  ${({ $active }) => {
    if ($active) {
      return css`
        color: ${plume.COLORS.PRIMARY_BLACK};

        ${Underlined}::after {
          left: 0;
          right: 0;
          opacity: 1;
        }
      `
    } else {
      return css`
        color: ${plume.COLORS.PRIMARY_BLACK};

        ${Underlined}::after {
          opacity: 0;
          transition: all 0.2s ease;
        }
      `
    }
  }}

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    ${({ $active }) => {
      if (!$active) {
        return css`
          &:hover {
            color: ${plume.COLORS.PRIMARY_BLACK} !important;

            ${Underlined}::after {
              left: 0;
              right: 0;
              opacity: 1;
            }
          }
        `
      }
    }}
  }
`

export const MenuItem = styled.li<{ firstItem?: boolean }>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  ${({ firstItem }) => {
    if (firstItem) {
      return css`
        ${TopLevelEntry} {
          padding: 4px 40px 4px 20px;
        }
      `
    } else {
      return css`
        ${TopLevelEntry} {
          padding: 4px 10px;
        }
      `
    }
  }}

  ${plume.styles.heading.XS} {
    font-style: italic;
    font-weight: 700;
    text-transform: none;
  }
`
