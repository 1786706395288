import * as React from 'react'
import NextLink from 'next/link'
import styled from 'styled-components'

import * as header from '@ulule/header'
import * as plume from '@ulule/owl-kit-components/next'

import * as cold from '@owl-nest/cold-data/client'
import * as cart from '@boutique/cart'
import { useHierarchyHref } from '@boutique/hooks'
import * as url from '@boutique/url'

import { XmasIcon } from './XmasIcon'

export function AppHeader({
  isDrawerOpen,
  setIsDrawerOpen,
}: {
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}): React.ReactElement {
  const [stack, setStack] = React.useState<string[]>([])
  const currentTree = cold.getTree(cold.hierarchy, stack[stack.length - 1])

  return (
    <header.boutique.Header
      onCloseDrawer={() => setStack([])}
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      mobileDrawerContent={
        currentTree.type === '$$root$$' ? (
          <Panel
            closeDrawer={() => setIsDrawerOpen(false)}
            tree={currentTree}
            onOpen={(slug) => setStack((stack) => [...stack, slug])}
            onBack={() => setStack((stack) => stack.slice(0, -1))}
          />
        ) : (
          <CoverAllWrapper>
            <Panel
              closeDrawer={() => setIsDrawerOpen(false)}
              tree={currentTree}
              onOpen={(slug) => setStack((stack) => [...stack, slug])}
              onBack={() => setStack((stack) => stack.slice(0, -1))}
            />
          </CoverAllWrapper>
        )
      }
      lang="fr"
      cart={<cart.PopupCart />}
    />
  )
}

type PanelProps = {
  tree: ReturnType<typeof cold.getTree>
  onOpen: (slug: string) => void
  onBack: () => void
  closeDrawer: () => void
}

function Panel({ tree, onOpen, onBack, closeDrawer }: PanelProps): React.ReactElement {
  const href = useHierarchyHref(tree.id)

  return (
    <>
      {tree.type !== '$$root$$' && (
        <>
          <BackButton onClick={onBack}>
            <plume.glyphs.stroke.CaretLeftLightweight size={16} />
            <plume.styles.copy.L as="span">{tree.name}</plume.styles.copy.L>
          </BackButton>
          <SeeAllLink href={href}>
            <plume.styles.copy.S>Voir tous les produits {tree.name}</plume.styles.copy.S>
            <plume.glyphs.stroke.ArrowRight size={16} />
          </SeeAllLink>
        </>
      )}
      <List>
        {tree.children.map((child) => {
          return (
            <ListItem key={child.id}>
              <HierarchyEntry slug={child.id} onOpen={() => onOpen(child.id)} closeDrawer={closeDrawer}>
                {child.name}
              </HierarchyEntry>
            </ListItem>
          )
        })}
        {tree.type === '$$root$$' && (
          <ListItem>
            <GiftIdeasLink href={url.giftIdeas().path}>
              <plume.styles.copy.S as="span">
                <XmasIcon /> Idées cadeaux
              </plume.styles.copy.S>
            </GiftIdeasLink>
          </ListItem>
        )}
      </List>
    </>
  )
}

type EntryProps = {
  slug: string
  className?: string
  onOpen?: () => void
  closeDrawer: () => void
  children: React.ReactNode
}

function HierarchyEntry({ slug, children, className, onOpen, closeDrawer }: EntryProps): React.ReactElement {
  const url = useHierarchyHref(slug)

  if (isCategory(slug)) {
    return (
      <CategoryButton onClick={onOpen}>
        <plume.styles.copy.S as="span">{children}</plume.styles.copy.S>
        <plume.glyphs.stroke.CaretRightLightweight size={16} />
      </CategoryButton>
    )
  }

  if (isProductType(slug)) {
    return (
      <ProductTypeLink className={className} href={url} onClick={closeDrawer}>
        <plume.styles.copy.S as="span">{children}</plume.styles.copy.S>
      </ProductTypeLink>
    )
  }

  return (
    <HierarchyButton onClick={onOpen}>
      <plume.styles.copy.S as="span">{children}</plume.styles.copy.S>
      <plume.glyphs.stroke.CaretRightLightweight size={16} />
    </HierarchyButton>
  )
}

const CoverAllWrapper = styled.div`
  position: absolute;
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  z-index: 1;
  inset: 0;
  display: flex;
  flex-direction: column;
`

const List = styled.ul`
  && {
    margin-bottom: 32px;
  }
`

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`

const BackButton = styled.button`
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  background: ${plume.COLORS.BLUE_SHADE_3};
  padding: 10px 20px 14px;
  display: flex;
  align-items: center;

  ${plume.styles.copy.L} {
    font-weight: 600;
  }

  ${plume.glyphs.stroke.CaretLeftLightweight} {
    margin-right: 8px;
    margin-top: 2px;
  }
`

const BaseHierarchyButton = styled.button`
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
`

const HierarchyButton = styled(BaseHierarchyButton)`
  padding: 0 20px;

  ${plume.glyphs.stroke.CaretRightLightweight} {
    color: ${plume.COLORS.GREY_SHADE_7};
  }
`

const CategoryButton = styled(BaseHierarchyButton)`
  ${plume.styles.copy.S} {
    text-transform: uppercase;
    font-weight: 600;
  }
`

const ProductTypeLink = styled(NextLink)`
  padding: 0 20px;
`

const SeeAllLink = styled(NextLink)`
  width: 100%;
  padding: 16px 20px;
  border-bottom: 1px solid ${plume.COLORS.GREY_SHADE_5};
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  ${plume.styles.copy.S} {
    font-weight: 600;
  }

  &:hover ${plume.styles.copy.S} {
    text-decoration: underline;
  }

  ${plume.glyphs.stroke.ArrowRight} {
    margin-left: 8px;
  }
`

const GiftIdeasLink = styled(NextLink)`
  width: 100%;

  ${plume.styles.copy.S} {
    background-color: ${plume.COLORS.SECONDARY_GREEN_LIGHT};
    color: ${plume.COLORS.SECONDARY_MINT};
    display: inline-flex;
    font-weight: 600;
    padding: 4px 8px;
    text-transform: uppercase;
    align-items: center;

    ${XmasIcon} {
      margin-right: 4px;
    }
  }
`

function isProductType(slug: string) {
  return cold.types['product-type'].includes(slug)
}

function isCategory(slug: string) {
  return cold.types['category'].includes(slug)
}
