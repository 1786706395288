import { type Gql, type Fragment, gql } from './gql.ts'

function resolveFragments(gql: Gql): Set<Fragment> {
  const fragments = new Set<Fragment>()
  const queue = [gql.fragments]
  let currentSet: Set<Fragment> | undefined
  while ((currentSet = queue.pop()) !== undefined) {
    for (const fragment of currentSet) {
      queue.push(fragment.gql.fragments)
      fragments.add(fragment)
    }
  }
  return fragments
}

export type Query<RESULT = any, VARIABLES = Record<string, any>> = string & {
  __result: RESULT
  __variables: VARIABLES
}

export function gqlToQuery<RESULT = any, VARIABLES = Record<string, any>>(queryGql: Gql): Query<RESULT, VARIABLES> {
  const fragments = resolveFragments(queryGql)
  const fullQueryGql = [queryGql.content]
  for (const fragment of fragments) {
    fullQueryGql.push(fragment.gql.content)
  }
  return fullQueryGql.join('\n') as any
}

export function query<RESULT = any, VARIABLES = Record<string, any>>(
  chunks: TemplateStringsArray,
  ...rest: any[]
): Query<RESULT, VARIABLES> {
  const queryGql = gql(chunks, ...rest)
  return gqlToQuery(queryGql)
}
