import * as React from 'react'
import * as jotai from 'jotai'

import * as hooks from '@owl-nest/hooks'
import * as shopify from '@owl-nest/shopify'
import { useFix } from '@owl-nest/geolocation'

import * as core from '@boutique/services'
import * as entity from '@boutique/entities'

import { useCart } from './useCart'
import { SHIPPING_COUNTRY_ATOM } from './useShippingCountry'

const SHIPPING_RATES_CACHE = jotai.atom<hooks.Cache<entity.shipping.ShippingRateData>>({})

type CartShippingRatesConfig = {
  forceGeolocatedCountry?: boolean
  fetchOnMount?: boolean | 'force'
}

export function useCartShippingRates({ forceGeolocatedCountry, fetchOnMount }: CartShippingRatesConfig) {
  const cart = useCart()

  const items =
    cart.ready && !cart.empty
      ? cart.data.vendors.flatMap((vendor) => {
          return vendor.lineItems.map((item) => ({
            shopifyVariantId: shopify.utils.toNumericId(item.variant.id, 'ProductVariant'),
            quantity: item.quantity,
          }))
        })
      : []

  const vendors = cart.ready && !cart.empty ? cart.data.vendors.map((vendor) => vendor.brand.identifier) : []

  return useShippingRates({
    forceGeolocatedCountry,
    items,
    vendors,
    fetchOnMount,
  })
}

type ShippingRatesConfig = {
  forceGeolocatedCountry?: boolean
  items: { shopifyVariantId: number; quantity: number }[]
  vendors: string[]
  fetchOnMount?: boolean | 'force'
}

export function useShippingRates(
  { forceGeolocatedCountry, items, vendors, fetchOnMount = false }: ShippingRatesConfig = { items: [], vendors: [] },
): hooks.Response<entity.shipping.ShippingRateData> {
  const fix = useFix()
  const geolocatedCountry = fix.status === hooks.AwaitStatus.SUCCESS ? fix.value.country_code : undefined
  const [shippingCountry] = jotai.useAtom(SHIPPING_COUNTRY_ATOM)

  const country = forceGeolocatedCountry ? geolocatedCountry : shippingCountry ?? geolocatedCountry

  const sharedQuery = hooks.useSharedQuery(core.shipping.client.getShippingRates, {
    atom: SHIPPING_RATES_CACHE,
    id,
  })

  hooks.useFetchOnMount(
    {
      fetchOnMount,
      getResponse,
      query,
    },
    id(country, items, vendors),
  )

  return getResponse()

  function getResponse(): hooks.Response<entity.shipping.ShippingRateData> {
    if (country === undefined || items.length === 0 || vendors.length === 0) {
      return { status: hooks.QueryStatus.PRISTINE }
    }

    return sharedQuery.getCurrentResponse([country, items, vendors])
  }

  function query(): Promise<void> | undefined {
    if (country === undefined || items.length === 0 || vendors.length === 0) {
      return
    }

    return sharedQuery.query(country, items, vendors)
  }
}

function id(
  country: string | undefined,
  items: {
    quantity: number
    shopifyVariantId: number
  }[],
  vendors: string[],
): string {
  return JSON.stringify([
    country,
    ...items.flatMap((item) => [item.quantity, item.shopifyVariantId]),
    ...vendors.sort(),
  ])
}
