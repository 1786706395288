import * as graphql from 'graphql-request'

export type Gql = {
  content: string
  fragments: Set<Fragment>
}

export function isGql(value: unknown): value is Gql {
  return (
    typeof value === 'object' &&
    value !== null &&
    'content' in value &&
    'fragments' in value &&
    typeof (value as any).content === 'string' &&
    (value as any).fragments instanceof Set
  )
}

export type Fragment = {
  name: string
  gql: Gql
}

export function isFragment(value: unknown): value is Fragment {
  return (
    typeof value === 'object' &&
    value !== null &&
    'name' in value &&
    'gql' in value &&
    typeof (value as any).name === 'string' &&
    isGql((value as any).gql)
  )
}

export function gql(chunks: TemplateStringsArray, ...rest: any[]): Gql {
  const fragments = new Set<Fragment>()
  const interpolations = rest.map((interpolation) => {
    if (isFragment(interpolation)) {
      fragments.add(interpolation)
      return interpolation.name
    }
    return interpolation
  })

  return {
    content: graphql.gql(chunks, ...interpolations),
    fragments,
  }
}

export function fragment(chunks: TemplateStringsArray, ...rest: any[]): Fragment {
  const fragmentGql = gql(chunks, ...rest)
  const match = fragmentGql.content.match(/\sfragment\s+(.*?)\s/)
  if (match === null) {
    throw Error(`${fragmentGql.content} is not a fragment`)
  }
  return {
    name: match[1],
    gql: fragmentGql,
  }
}
