import * as React from 'react'
import styled, { css } from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

type DiscountProps = {
  inPopup?: boolean
  name: string
  src: string
  isBusy: boolean
  onAccept: () => void
  onReject: () => void
}

export function Discount(props: DiscountProps): React.ReactElement {
  switch (props.name) {
    case 'free-sogood': {
      return <FreeSoGood {...props} />
    }
  }
  return <></>
}

function FreeSoGood({ isBusy, inPopup, src, onAccept, onReject }: DiscountProps): React.ReactElement {
  return (
    <DiscountWrapper inPopup={inPopup}>
      <plume.styles.copy.M>
        Souhaitez-vous recevoir <strong>4 numéros gratuits</strong> du magazine So&nbsp;good ?
      </plume.styles.copy.M>
      <plume.Button disabled={isBusy} onClick={onAccept}>
        Oui, ajouter !
      </plume.Button>
      <plume.Picture src={src} />
      <plume.glyphs.stroke.Close size={18} onClick={onReject} />
    </DiscountWrapper>
  )
}

const DiscountWrapper = styled.div<{ inPopup?: boolean }>`
  background-color: ${plume.COLORS.PRIMARY_BLUE_100};
  position: relative;
  border-radius: 4px;
  padding: 25px 120px 25px 12px;
  margin-bottom: 7px;
  overflow: hidden;

  ${plume.glyphs.stroke.Close} {
    position: absolute;
    top: 23px;
    right: 23px;
  }

  ${plume.styles.copy.M} {
    margin-bottom: 20px;
  }

  ${plume.Picture} {
    width: 135px;
    position: absolute;
    transform: rotateZ(13deg);
    top: 50px;
    right: 0;
  }

  ${({ inPopup }) => {
    if (!inPopup) {
      return css`
        margin-bottom: 20px;
        padding-right: 100px;
        padding-left: 12px;

        ${plume.Picture} {
          right: -30px;
        }

        @media screen and (${plume.BREAKPOINTS.MOBILE_M}) {
          padding-right: 120px;
          ${plume.Picture} {
            right: -10px;
          }
        }

        @media screen and (${plume.BREAKPOINTS.MOBILE_L}) {
          padding-right: 48%;
          ${plume.Picture} {
            right: 5%;
          }
        }

        @media screen and (${plume.BREAKPOINTS.LAPTOP}) {
          padding-right: 38.27%;
          ${plume.Picture} {
            right: 7.97%;
          }
        }

        @media screen and (${plume.BREAKPOINTS.LAPTOP_L}) {
          padding-right: 240px;
          ${plume.Picture} {
            right: 50px;
          }
        }
      `
    }
  }}
`
