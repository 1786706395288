import * as React from 'react'
import Link from 'next/link'

import * as entities from '@boutique/entities'
import * as url from '@boutique/url'

import * as plume from '@ulule/owl-kit-components/next'

type HyperlinkProps = {
  uri: string
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

export function Hyperlink({ children, uri }: HyperlinkProps): React.ReactElement {
  const url = new URL(uri)

  const isJavascript = url.protocol.includes('javascript')

  // Required to launch Axeptio widget
  if (isJavascript) {
    return <plume.styles.wysiwyg.Hyperlink href={uri}>{children}</plume.styles.wysiwyg.Hyperlink>
  }

  return (
    <plume.styles.wysiwyg.Hyperlink href={uri} rel="noopener noreferer external" target="_blank">
      {children}
    </plume.styles.wysiwyg.Hyperlink>
  )
}
