import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'
import { Callout } from './notification/Callout'
import { Link } from './Link'
import { QuantityInput } from './QuantityInput'

export type CartItemProps = {
  deliveryWarningMessage?: string
  className?: string
  discountedPrice?: string
  href: string
  image: string
  price: string
  quantity: number
  subtitle: string
  title: string
  LinkComponent?: React.ComponentType<{ href: string; children?: React.ReactNode }>
  onIncrement?: () => Promise<void>
  onDecrement?: () => Promise<void>
  onRemove?: () => Promise<void>
  disabled: boolean
}

export function CartItemComponent({
  className,
  deliveryWarningMessage,
  discountedPrice,
  href,
  image,
  price,
  quantity,
  subtitle,
  title,
  LinkComponent,
  onIncrement,
  onDecrement,
  onRemove,
  disabled,
}: CartItemProps): React.ReactElement {
  return (
    <S.cartItemBody.Wrapper className={className}>
      <Link as={LinkComponent} href={href}>
        <S.cartItemBody.SquarePicture sizing="cover" src={image} />
      </Link>
      <S.cartItemBody.Content>
        <Link as={LinkComponent} href={href}>
          <S.cartItemBody.Title asDesktop="L">{title}</S.cartItemBody.Title>
          <S.cartItemBody.Subtitle>{subtitle}</S.cartItemBody.Subtitle>
        </Link>
        {discountedPrice !== undefined ? (
          <>
            <S.cartItemBody.DiscountedPrice>{price}</S.cartItemBody.DiscountedPrice>
            <S.cartItemBody.Price>{discountedPrice}</S.cartItemBody.Price>
          </>
        ) : (
          <S.cartItemBody.Price>{price}</S.cartItemBody.Price>
        )}
      </S.cartItemBody.Content>
      {deliveryWarningMessage && <Callout type="info">{deliveryWarningMessage}</Callout>}
      <QuantityInput
        quantity={quantity}
        disabled={disabled}
        onIncrement={onIncrement}
        onDecrement={onDecrement}
        onRemove={onRemove}
      />
    </S.cartItemBody.Wrapper>
  )
}

export const CartItem = styled(CartItemComponent)`
  ${Callout} {
    margin-top: 10px;
    width: 100%;
    margin-bottom: -4px;
  }
`
