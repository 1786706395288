import Link from 'next/link'
import * as React from 'react'
import styled, { css } from 'styled-components'

import * as countries from '@owl-nest/countries'
import * as geoloc from '@owl-nest/geolocation'
import * as hooks from '@owl-nest/hooks'
import * as money from '@owl-nest/money'
import * as url from '@boutique/url'
import * as header from '@ulule/header'
import * as plume from '@ulule/owl-kit-components/next'

import { useCart, useCartItemCount } from '../hooks/useCart'
import { useCartShippingRates } from '../hooks/useShippingRates'
import { CartItemList } from './CartItemList'

export function PopupCart(): React.ReactElement {
  const [isCartOpen, setIsCartOpen] = React.useState(false)

  const cart = useCart()
  const itemCount = useCartItemCount()
  const router = hooks.useNextRouter()

  // open cart popup on non-cart pages when the quantity changes
  const previousCount = hooks.usePrevious(cart.ready && !cart.empty ? itemCount : 0)
  React.useEffect(() => {
    const cartTouched = cart.ready && !cart.empty && cart.touched
    const isNotCartView = router.pathname !== '/cart'
    const itemsAdded = itemCount > (previousCount ?? 0)

    if (cartTouched && !plume.utils.isMobile() && isNotCartView && itemsAdded) {
      setIsCartOpen(true)
    }
  }, [cart, itemCount, isCartOpen, previousCount, router.asPath])

  React.useEffect(() => {
    setIsCartOpen(false)
  }, [router.asPath])

  const [isBodyScrollable, setIsBodyScrollable] = React.useState(false)

  const fix = geoloc.useFix()
  const geolocatedCountry = fix.status === hooks.AwaitStatus.SUCCESS ? fix.value.country_code : undefined

  const response = useCartShippingRates({ forceGeolocatedCountry: true, fetchOnMount: true })

  const isLargerThanTablet = hooks.useDramaticallyInefficientMediaQuery(header.styles.INTERMEDIATE_BREAKPOINT)

  return (
    <>
      <MarketItem
        onClick={() => {
          if (!isLargerThanTablet) {
            window.location.href = url.cart().path
          } else {
            setIsCartOpen(!isCartOpen)
          }
        }}
      >
        <plume.glyphs.stroke.Market size={22} />
        {itemCount > 0 && <Number>{itemCount}</Number>}
      </MarketItem>
      {isLargerThanTablet && (
        <Popup
          // The header will disappear on scroll, and the sub-header will stick to the top of the window.
          // This means that the modal overlay needs to have an offset from the top that depends on the scroll.
          // When there is no scroll, the offset needs to be `HEADER_HEIGHT` + `HEADER_SUB_NAV_HEIGHT`.
          // When there is enough scroll (the header disappeared), the offset needs to be `HEADER_SUB_NAV_HEIGHT`.
          // When the scroll is between the two, the offset needs to be `HEADER_SUB_NAV_HEIGHT` + `HEADER_HEIGHT` - amount_currently_scrolled
          // Those three conditions are compressed in one with the use of `Math.max(0, ...)`
          $top={
            parseInt(plume.SIZES.HEADER_SUB_NAV_HEIGHT, 10) +
            Math.max(0, parseInt(plume.SIZES.HEADER_HEIGHT, 10) - (typeof window === 'undefined' ? 0 : window.scrollY))
          }
          lockScroll
          open={isCartOpen}
          closable
          onClose={() => setIsCartOpen(false)}
        >
          <Body
            ref={(node) => {
              if (!node) {
                return
              }

              setIsBodyScrollable(node.scrollHeight > 514)
            }}
          >
            <Head>
              <plume.styles.heading.S>{cart.ready && cart.empty ? 'Panier vide' : 'Mon Panier'}</plume.styles.heading.S>
              <plume.styles.copy.M>
                {!cart.ready ? 'Chargement…' : !cart.empty && getItemCountLabel(itemCount)}
              </plume.styles.copy.M>
            </Head>
            <CartItemList
              freeShippingThresholds={response.data?.freeShippingThresholds}
              inPopup
              shippingCountry={geolocatedCountry && countries.frenchCountryList[geolocatedCountry]}
            />
          </Body>
          {cart.ready && !cart.empty && (
            <Footer withShadow={isBodyScrollable}>
              <plume.styles.copy.L>Total panier</plume.styles.copy.L>
              <plume.styles.copy.L>{money.format(cart.total())}</plume.styles.copy.L>
              <Link passHref href={url.cart().path} legacyBehavior>
                <plume.ButtonAsLink size="medium">Voir mon panier</plume.ButtonAsLink>
              </Link>
            </Footer>
          )}
        </Popup>
      )}
    </>
  )
}

const MarketItem = styled.div`
  list-style: none;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: -7px;

  @media screen and ${header.styles.INTERMEDIATE_BREAKPOINT} {
    align-items: center;
    background-color: #2e8d87; /* HACK: Replace with COLORS.SECONDARY_MINT_700 */
    color: ${plume.COLORS.PRIMARY_WHITE};
    cursor: pointer;
    height: 53px;
    margin-left: 20px;
    margin-top: 5px;
    width: 56px;
  }
`

const Number = styled(plume.styles.copy.XS)`
  background-color: #d9f8f6; /* HACK: Replace with COLORS.SECONDARY_MINT_300 */
  border-radius: 100%;
  bottom: 15px;
  color: #2e8d87; /* HACK: Replace with COLORS.SECONDARY_MINT_700 */
  height: 16px;
  position: absolute;
  right: 12px;
  width: 16px;
  text-align: center;
  line-height: 14px; /* HACK: Irregular font manipulation */

  @media screen and ${header.styles.INTERMEDIATE_BREAKPOINT} {
    bottom: 10px;
  }
`

const Body = styled.div`
  overflow: auto;
  position: relative;
  max-height: 424px;
  padding: 20px 20px 0 20px;

  ${plume.styles.cartItemHeader.Picture} {
    width: 40px;
    height: 40px;
  }

  ${plume.styles.cartItemHeader.Title} {
    ${plume.styles.copy._largeStyle}
    padding-left: 8px;
    font-weight: 900;
  }

  ${plume.styles.cartItemBody.Title} {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
`

const Head = styled.div`
  text-align: center;

  ${plume.styles.copy.M} {
    margin-bottom: 16px;
  }
`

const Footer = styled.div<{ withShadow?: boolean }>`
  position: relative;
  padding: 16px 20px 20px 20px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${plume.styles.copy.L} {
    font-weight: 600; /* HACK: Irregular font manipulation. We'll be adding copy.L.semiBold, so this is just by anticipation. */
    margin-bottom: 20px;

    :last-of-type {
      /*  FIXME : this color is currently not in the DS */
      color: #2e8d87;
    }
  }

  ${plume.ButtonAsLink} {
    width: 100%;
    ${plume.styles.button.Button} {
      width: 100%;
    }
  }

  ${({ withShadow }) => {
    if (withShadow) {
      return css`
        &::before {
          content: '';
          height: 15px;
          background: linear-gradient(transparent, #000);
          display: block;
          position: absolute;
          top: -15px;
          opacity: 0.1;
          left: -20px;
          right: -20px;

          @media screen and ${plume.BREAKPOINTS.TABLET} {
            left: 0;
            right: 0;
          }
        }
      `
    }
  }}
`

const Popup = styled(plume.Modal)<{ $top: number }>`
  ${plume.styles.modal.Wrapper} {
    &::before {
      display: none;
    }
    & ${plume.glyphs.stroke.Close} {
      display: none;
    }
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    overflow: hidden;

    && {
      top: ${({ $top }) => $top}px;

      & > ${plume.styles.modal.Wrapper} {
        border-radius: 0px;
        position: fixed;
        top: ${({ $top }) => $top}px;
        right: 0;
        left: unset;
        width: 375px;
        bottom: unset;

        &[step='before'] {
          transform: translate(100vh, 0);
        }
        &[step='after'] {
          transform: translate(100vh, 0);
        }

        & > ${plume.styles.modal.Body} {
          padding: 0;

          & > ${Head} {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
`

function getItemCountLabel(itemCount: number): string {
  if (itemCount === 0) {
    return 'Aucun article'
  } else if (itemCount === 1) {
    return '1 article'
  } else {
    return `${itemCount} articles`
  }
}
