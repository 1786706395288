import * as React from 'react'
import * as jotai from 'jotai'

import * as api from '@owl-nest/api-client/latest'
import * as hooks from '@owl-nest/hooks'
import { fragment, query } from '@owl-nest/graphql'
import { Request, Response } from '@owl-nest/contentful'

type Locale = api.Locale | 'fr'
export type FooterItem = {
  label: string
  href: string
  openInNewTab: boolean
  language?: api.Lang
}

type Item = {
  label: string
  href: string
  openInNewTab: boolean
  language?: api.Lang
}

export type FooterCollectionResult = {
  footerCollection: {
    items: {
      leftColumnTitle: string
      rightColumnTitle: string
      centerColumnTitle: string
      leftColumnLinksCollection: { items: Item[] }
      rightColumnLinksCollection: { items: Item[] }
      centerColumnLinksCollection: { items: Item[] }
    }[]
  }
}

export type FooterItemInput = {
  locale: Locale
  skip: number
  limit: number
}

const FOOTER_ITEM_FRAGMENT = fragment`
    fragment FooterItem on FooterItem {
       label
       href
       openInNewTab
    }
`

const FOOTER_COLLECTION_QUERY = query<FooterCollectionResult, FooterItemInput>`
    query($locale: String, $skip: Int!, $limit: Int!) {
      footerCollection(
            locale: $locale
            limit: $limit
            skip: $skip
        ) {
          items {
            leftColumnTitle
            leftColumnLinksCollection(
              locale: $locale
              limit: $limit
              skip: $skip
          ) {
              items {
                ...${FOOTER_ITEM_FRAGMENT}
              }
            }
            rightColumnTitle
            rightColumnLinksCollection(
              locale: $locale
              limit: $limit
              skip: $skip
          ) {
              items {
                ...${FOOTER_ITEM_FRAGMENT}
              }
            }
            centerColumnTitle
            centerColumnLinksCollection(
              locale: $locale
              limit: $limit
              skip: $skip
          ) {
              items {
                ...${FOOTER_ITEM_FRAGMENT}
              }
            }
      }}
    }
`

export async function getFooterCollection(input: FooterItemInput, request: Request): Response<FooterCollectionResult> {
  const result = await request(FOOTER_COLLECTION_QUERY, input)
  return result
}

export async function listForFooter(locale: Locale, request: Request) {
  const response = await getFooterCollection(
    {
      locale,
      skip: 0,
      limit: 30,
    },
    request,
  )

  return response.next((response) => response)
}

export type FooterCollectionParams = {
  locale: Locale
}

type FooterCollectionConfig = {
  fetchOnMount?: boolean | 'force'
  params: FooterCollectionParams
  request: Request
}

export type Cache = hooks.Cache<FooterCollectionResult>
export const FOOTER_CACHE = jotai.atom<Cache>({})

export const SHARED_FOOTER_COLLECTION = jotai.atom<hooks.Cache<FooterCollectionResult>>({})

export function useFooterCollection({ fetchOnMount = true, request, params }: FooterCollectionConfig) {
  const sharedQuery = hooks.useSharedQuery(
    async ({ locale }: FooterCollectionParams) => listForFooter(locale, request),
    {
      atom: SHARED_FOOTER_COLLECTION,
      id,
    },
  )

  hooks.useFetchOnMount(
    {
      fetchOnMount,
      query,
      getResponse,
    },
    id(params),
  )

  return { response: getResponse() }

  function getResponse() {
    return sharedQuery.getCurrentResponse([params])
  }

  function query() {
    return sharedQuery.query(params)
  }
}

export function id({ locale }: FooterCollectionParams): string {
  return JSON.stringify([locale])
}
