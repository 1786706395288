import styled from 'styled-components'

import * as breakpoints from '../../constants/breakpoints'
import * as colors from '../../constants/colors'
import * as copy from '../copy'
import * as heading from '../heading'

export type ImageProps = {
  sizing?: 'contain' | 'cover'
}

export const Content = styled.div`
  max-width: 624px;
  padding: 0px 0px 0px 15px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Image = styled.img<ImageProps>`
  object-fit: ${({ sizing = 'cover' }) => sizing};
  object-position: center;
  width: 100%;
  height: 100%;

  // HACK: IE: hide image, object-fit not supported
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    opacity: 0;
  }
`
export const SquarePicture = styled(Image)`
  border-radius: 10px;
  height: 120px;
  min-width: 120px;
  width: 120px;
`

export const Title = styled(copy.M)`
  color: ${colors.PRIMARY_BLACK};
  font-weight: 600; // HACK: Irregular font manipulation. We'll be adding copy.M.semiBold, so this is just by anticipation.
`

export const Subtitle = styled(copy.S)`
  color: ${colors.GREY_SHADE_2};
  flex-basis: 100%;
  font-weight: 600; // HACK: Irregular font manipulation. We'll be adding copy.S.semiBold, so this is just by anticipation.
`

export const Price = styled(copy.M)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-weight: 600; // HACK: Irregular font manipulation. We'll be adding copy.M.semiBold, so this is just by anticipation.
  align-self: flex-end;
`

export const DiscountedPrice = styled(copy.S)`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  width: 100%;
  text-decoration: line-through;
  color: ${colors.GREY_SHADE_4};
`

export const QuantityWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  padding-top: 16px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 584px;
  padding-bottom: 30px;
`
