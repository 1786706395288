import * as React from 'react'
import styled from 'styled-components'

import type * as api from '@owl-nest/api-client/latest'
import { ConfigurationProvider, UserDrawerProvider, SearchProductDrawer, subnavStyles } from '@ulule/header'
import * as plume from '@ulule/owl-kit-components/next'

import { BoutiqueEntry } from './BoutiqueEntry'
import { CategoriesEntries } from './CategoriesEntries'

import * as UFE from '../UFE'

type BoutiqueSubnavProps = {
  className?: string
  category?: string
  cart: React.ReactNode
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DEFAULT_CONFIGURATION = {
  defaultLocale: 'fr' as api.Lang,
  locale: 'fr' as api.Lang,
  translations: {
    searchDrawer: {
      searchPlaceholder: 'Rechercher un produit',
    },
  },
}

function BoutiqueSubnavComponent({
  className,
  cart,
  category,
  isDrawerOpen,
  setIsDrawerOpen,
}: BoutiqueSubnavProps): React.ReactElement {
  return (
    <UserDrawerProvider lang={UFE.USER_LOCALE ?? UFE.DEFAULT_LOCALE}>
      <ConfigurationProvider configuration={DEFAULT_CONFIGURATION}>
        <BoutiqueNavWrapper className={className}>
          <EntriesWrapper>
            <BoutiqueEntry forceCategory={category} />
            <CategoriesEntries forceCategory={category} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
          </EntriesWrapper>

          <div>
            <SearchProductDrawer action="/produits" />
            {cart}
          </div>
        </BoutiqueNavWrapper>
      </ConfigurationProvider>
    </UserDrawerProvider>
  )
}

const BoutiqueNavWrapper = styled(subnavStyles.SubnavWrapper)`
  align-items: center;
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  border-bottom: 6px solid #d9f8f6; /* HACK: Replace with COLORS.SECONDARY_MINT_300 */
  display: flex;
  justify-content: space-between;
  position: relative;
`

const EntriesWrapper = styled.div`
  display: flex;
  height: 100%;
  position: relative;

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    position: static;
  }
`

export const BoutiqueSubnav = styled(BoutiqueSubnavComponent)``
