import * as React from 'react'

import * as entity from '@boutique/entities'
import * as services from '@owl-nest/services'

import { useCart } from '../useCart'
import { ReadyCart, EmptyCart } from '../types'
import { UseDiscount } from './type'

let globalLock = false

export type Config = {
  name: string
  image: string
  threshold: number
  discountCodeTrigger: string
  variantId: string
  quantity: number
}

/**
 * A chaque modification du cart on vérifie :
 * - Si le cart est vide ou pas pret, on ne fait rien
 * - Si une instance du hook est déja en train de tourner, on ne fait
 *   rien (`globalLock`)
 * - Sinon on prend le `globalLock` pour empecher les autres instance de s'executer.
 * - Si on est au dessus du seuil, qu'aucun produit marqué "discount" n'est présent,
 *   et que le cart n'est pas marqué "discount", on ajoute le produit offert
 *   (marqué "discount") et on marque le cart. C'est la condition basique d'ajout
 *   du produit si on dépasse le seuil.
 * - Si le code automatique shopify ne s'applique pas et qu'on a un produit
 *   marqué "discount" et que le cart est marqué "discount", on retire le produit
 *   offert (celui marqué "discount") et on retire la marque du cart. C'est la
 *   condition basique de retrait du produit si on passe sous le seuil (en detectant
 *   l'absence du code automatique shopify, pour rester iso avec les règles shopify).
 * - Si le code automatique shopify ne s'applique pas, qu'on a aucun produit
 *   marqué "discount", que le cart est marqué "discount" et qu'on est sous le seuil,
 *   on retire la marque du cart. C'est ce qui permet de "réarmer" la règle si un
 *   utilisateur suprime le produit offert. Il peut enelever des produit pour passer
 *   sous le seuil, puis les ajouter à nouveau pour déclancher de nouveau la condition
 *   basique d'ajout du produit.
 * - Enfin, on relache le `globalLock`.
 *
 * La marque "discount" sur le produit permet de cibler correctement le produit à
 * enlever si le code automatique shopify ne s'applique plus.
 *
 * La marque "discount" sur le cart permet à l'utilisateur de supprimer le produit
 * offert si il le souhaite sans qu'il ne soit a nouveau ajouté automatiquement.
 * C'est cette fonctionnalité qui necessite la 3eme condition, pour "réarmer" la
 * règle.
 *
 * Le seuil nous permet de décider quand ajouter le produit, et la disparition du
 * code automatique nous permet de décider quand le supprimer. On ne peut pas se
 * baser sur le seuil pour la suppression :
 *   si un panier contient un produit à 20€ et un produit à 30€ et que la promotion
 *   s'applique à 50€, nous offrant un produit à 25€, on se retourve avec un panier
 *   contenant un produit à 20€, un produit à 30€ et un produit à 25€ offert.
 *   Si l'utilisateur retire le produit à 20€, on se retrouve avec un produit à 30€
 *   et un produit à 25€ qui n'est plus offert (shopify applique le code en calculant
 *   le total du cart moins le produit ciblé par le code). Comme il n'est plus offert
 *   le montant total du panier est de 55€, au dessus du seuil, donc on ne supprime
 *   pas le produit (alors qu'il faudrait)
 * On ne peut pas non plus se baser sur le code automatique de shopify pour l'ajout
 * du produit :
 *   Shopify n'applique le code que si la condition est remplie ET si le produit
 *   cible est présent dans le panier (donc il faut ajouter le produit au panier,
 *   pour que le code s'active, pour qu'on sache qu'il faut ajouter le produit au
 *   panier ...)
 */

export function useFreeProductOverThreshold(config: Config | undefined): UseDiscount {
  const cart = useCart()
  const toast = services.message.useMessage()

  const [isApplicable, setIsApplicable] = React.useState(false)

  React.useEffect(() => {
    // async iife to have access to `await` keyword inside `useEffect`
    ;(async () => {
      if (!cart.ready || config === undefined) {
        return
      }

      if (cart.empty) {
        if (config !== undefined) {
          window.localStorage.removeItem(`bob-discount-${config.name}`)
        }

        if (cart.data !== undefined && isCheckoutDiscounted(cart.data)) {
          await unMarkCheckout(cart)
        }
        return
      }

      if (globalLock) {
        return
      }

      globalLock = true

      const isOverThreshold = cart.total() >= config.threshold
      const isUnderThreshold = cart.total() < config.threshold

      const hasDiscountCodeTrigger = cart.data.discountCodes.some((code) => {
        return code.kind === 'automatic' && code.title === config.discountCodeTrigger
      })

      const discountedProduct = getDiscountedLineItem(cart.data)

      if (
        // when we are over the threshold an the promotion was not
        // yet applied, the promotion becomes applicable
        isOverThreshold &&
        discountedProduct === undefined &&
        !isCheckoutDiscounted(cart.data)
      ) {
        setIsApplicable(true)
      } else if (
        // when the discount code is no longer applied, but the promotion
        // was applied (the checkout is marked and there is a discounted product)
        // the promotion becomes not applicable and we remove the discounted product
        !hasDiscountCodeTrigger &&
        discountedProduct !== undefined &&
        isCheckoutDiscounted(cart.data)
      ) {
        setIsApplicable(false)
        await removeDiscountedProduct(cart, discountedProduct)
        await unMarkCheckout(cart)
        //FIXME: use messenger
        /*toast.dispatch({
          id: Date.now(),
          status: 'alert',
          children: `Vous ne bénéficiez plus de l'offre promotionnelle car le montant de votre panier est inférieur à ${config.threshold} €.`,
        })*/
      } else if (
        // when the discount code is not applied, there is no discounted
        // product but the cart is marked, and we get under the threshold
        // or when we are still over the threshold but the discounted product
        // was manually removed
        // the cart is unmarked
        !hasDiscountCodeTrigger &&
        discountedProduct === undefined &&
        isCheckoutDiscounted(cart.data)
      ) {
        setIsApplicable(false)
        await unMarkCheckout(cart)
      } else if (
        // when the promotion is applicable, but the promotion was not applied
        // (no discounted product and cart unmarked) and we get under the
        // threshold, the promtion becomes not applicable.
        isApplicable &&
        discountedProduct === undefined &&
        !isCheckoutDiscounted(cart.data) &&
        isUnderThreshold
      ) {
        setIsApplicable(false)
      }

      globalLock = false
    })()
    // we can safely ignore any other dependencies, since we use the `useEffect`
    // as a way to react to any change to `cart`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  if (config === undefined) {
    return { isApplicable: false }
  }

  if (isApplicable) {
    return { name: config.name, image: config.image, isApplicable, accept, reject }
  } else {
    return { isApplicable }
  }

  async function accept() {
    if (!cart.ready || cart.empty || !isApplicable) {
      return
    }

    await addDiscountedProduct(cart)
    await markCheckout(cart)
    setIsApplicable(false)
  }

  async function reject() {
    if (!cart.ready || cart.empty || !isApplicable) {
      return
    }

    await markCheckout(cart)
    setIsApplicable(false)
  }

  async function markCheckout(cart: ReadyCart | EmptyCart, value = 'true') {
    if (config === undefined || cart.data === undefined) {
      return
    }

    await cart.updateAttributes(
      cart.data.customAttributes
        .filter((entry) => entry.key !== config.discountCodeTrigger)
        .concat({ key: config.discountCodeTrigger, value: value }),
    )
  }

  async function unMarkCheckout(cart: ReadyCart | EmptyCart) {
    await markCheckout(cart, 'false')
  }

  async function addDiscountedProduct(cart: ReadyCart) {
    if (config === undefined) {
      return
    }

    const { data } = await cart.add([
      {
        quantity: config.quantity,
        variantId: config.variantId,
      },
    ])

    if (data) {
      const addedLineItem = getAddedLineItem(data)
      if (addedLineItem !== undefined) {
        window.localStorage.setItem(`bob-discount-${config.name}`, addedLineItem.id)
      }
    }
  }

  async function removeDiscountedProduct(cart: ReadyCart, discountedProduct: entity.checkout.LineItem) {
    if (config === undefined) {
      return
    }

    if (discountedProduct.totalVariantQuantity === config.quantity) {
      await cart.remove([discountedProduct])
    } else {
      await cart.update([
        {
          ...discountedProduct,
          quantity: discountedProduct.quantity - config.quantity,
        },
      ])
    }
    window.localStorage.removeItem(`bob-discount-${config.name}`)
  }

  function getAddedLineItem(nextCheckout: entity.checkout.Checkout) {
    if (!cart.ready || cart.empty) {
      return
    }

    const previousLineItems: {
      [s: string]: { variantId: string; quantity: number }
    } = {}

    for (const vendor of cart.data.vendors) {
      for (const lineItem of vendor.lineItems) {
        previousLineItems[lineItem.id] = {
          variantId: lineItem.variant.id,
          quantity: lineItem.quantity,
        }
      }
    }

    for (const vendor of nextCheckout.vendors) {
      for (const lineItem of vendor.lineItems) {
        const previous = previousLineItems[lineItem.id]
        // if lineItem did not exists previously, it is the added line item
        if (previous === undefined) {
          return lineItem
        }
        // if lineItem did exists previously, but with a different quantity, it
        // is the added line item
        if (previous.quantity !== lineItem.quantity) {
          return lineItem
        }
      }
    }
  }

  function getDiscountedLineItem(checkout: entity.checkout.Checkout): entity.checkout.LineItem | undefined {
    if (config === undefined) {
      return
    }

    const addedLineItemId = window.localStorage.getItem(`bob-discount-${config.name}`)

    if (addedLineItemId === null) {
      return
    }

    // try exact match (id & variantID & quantity)
    for (const vendor of checkout.vendors) {
      for (const lineItem of vendor.lineItems) {
        if (
          lineItem.variant.id === config.variantId &&
          lineItem.quantity === config.quantity &&
          lineItem.id === addedLineItemId
        ) {
          return lineItem
        }
      }
    }

    // if there is multiple instance of the product and the discount code
    // is no longer applied, sgopify merges multiples line items (those not
    // discounted with those discounted).
    // This mean that the `addedLineItemId` might no longer be present
    // If we did not find an exact match, we try to find a lineItem
    // with the same variantId and a quantity superior to what was added.
    // we should find the "merged" lineItem that way.
    for (const vendor of checkout.vendors) {
      for (const lineItem of vendor.lineItems) {
        if (lineItem.variant.id === config.variantId && lineItem.quantity >= config.quantity) {
          return lineItem
        }
      }
    }
  }

  function isCheckoutDiscounted(checkout: entity.checkout.Checkout): boolean {
    if (config === undefined) {
      return false
    }

    return checkout.customAttributes.some((entry) => {
      return entry.key === config.discountCodeTrigger && entry.value === 'true'
    })
  }
}
