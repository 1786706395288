type Cancelable<RESULT> = {
  promise: Promise<RESULT>
  cancel: () => void
}

export function cancelable<RESULT>(promise: Promise<RESULT>): Cancelable<RESULT> {
  let isCanceled = false

  const wrappedPromise = new Promise<RESULT>((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      (error) => reject({ isCanceled, error }),
    )
  })

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  }
}
