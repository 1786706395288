import * as React from 'react'
import * as jotaiUtils from 'jotai/utils'
import * as jotai from 'jotai'

import * as entity from '@boutique/entities'
import * as core from '@boutique/services'
import * as services from '@owl-nest/services'
import { getStoredUTM } from '@owl-nest/utm'

import { Cart } from './types'

const CHECKOUT_ID_LOCAL_STORAGE_KEY = 'bob_checkout_id'
const CHECKOUT_LOCAL_STORAGE_KEY = 'bob_checkout_content'

const IS_CHECKOUT_SYNC_ATOM = jotai.atom(false)
const IS_CHECKOUT_READY = jotai.atom(false)
const CHECKOUT_ATOM = jotaiUtils.atomWithStorage<undefined | entity.checkout.Checkout>(
  CHECKOUT_LOCAL_STORAGE_KEY,
  undefined,
)

const WAS_CART_TOUCHED_ATOM = jotai.atom(false)

export function useCart(): Cart {
  const store = jotai.useStore()
  const [{ checkout, ready }, setCheckout] = useCheckout()

  if (!ready) {
    return { ready: false, touched: false }
  }

  if (checkout === undefined || checkout.vendors.length === 0) {
    return {
      empty: true,
      data: checkout,
      ready: true,
      touched: store.get(WAS_CART_TOUCHED_ATOM),
      add,
      total: () => 0,
      updateAttributes,
      applyDiscountCodeToCheckout,
    }
  }

  return {
    data: checkout,
    empty: false,
    touched: store.get(WAS_CART_TOUCHED_ATOM),
    ready: true,
    add,
    navigateToCheckout,
    remove,
    update,
    total,
    updateAttributes,
    applyDiscountCodeToCheckout,
  }

  async function navigateToCheckout() {
    if (checkout === undefined) {
      return
    }

    const upToDateCheckout = await core.checkout.client.get(checkout.id)
    if (upToDateCheckout === null) {
      return
    }

    const cleanCheckout = await setCheckout(upToDateCheckout)

    if (cleanCheckout.vendors.length === 0) {
      return
    }

    const webUrl = new URL(cleanCheckout?.webUrl)
    webUrl.searchParams.append('locale', 'fr')

    const primaryUrl = process.env.NEXT_PUBLIC_SHOPIFY_PRIMARY_URL
    if (primaryUrl) {
      const shopUrl = new URL(primaryUrl)
      webUrl.hostname = shopUrl.hostname
      webUrl.protocol = shopUrl.protocol
    }

    //await clean()
    window.location.href = `${webUrl}`
  }

  async function add(lineItems: entity.checkout.PartialLineItem[]): Promise<{
    error?: entity.checkout.CheckoutError[]
    data?: entity.checkout.Checkout
  }> {
    if (!ready) {
      console.error("can't useCart.add if local storage is not ready ")
      return { error: [] }
    }

    store.set(WAS_CART_TOUCHED_ATOM, true)

    try {
      if (checkout === undefined) {
        const utm = getStoredUTM()

        // localstorage ready, but no checkout id. We must create it
        const checkout = await core.checkout.client.create(
          lineItems,
          Object.entries(utm).map(([key, value]) => ({ key, value })),
        )

        setCheckout(checkout)

        return { data: checkout }
      } else {
        // checkout already exists, simply add items
        const updatedCheckout = await core.checkout.client.addLineItems(checkout.id, lineItems)

        await setCheckout(updatedCheckout)

        return { data: updatedCheckout }
      }
    } catch (error: any) {
      return { error }
    }
  }

  async function update(lineItems: entity.checkout.LineItem[]): Promise<entity.checkout.CheckoutError[]> {
    if (!ready) {
      console.error("can't useCart.update if local storage is not ready ")
      return []
    }

    try {
      if (checkout === undefined) {
        console.error("can't useCart.update if no cart was created")
        return []
      } else {
        store.set(WAS_CART_TOUCHED_ATOM, true)

        const updatedCheckout = await core.checkout.client.updateLineItems(checkout.id, lineItems)

        await setCheckout(updatedCheckout)
      }
    } catch (error: any) {
      return error
    }

    return []
  }

  async function remove(lineItems: { id: string }[]): Promise<entity.checkout.CheckoutError[]> {
    if (!ready) {
      console.error("can't useCart.remove if local storage is not ready ")
      return []
    }

    try {
      if (checkout === undefined) {
        console.error("can't useCart.remove if no cart was created")
        return []
      } else {
        store.set(WAS_CART_TOUCHED_ATOM, true)

        const updatedCheckout = await core.checkout.client.removeLineItems(checkout.id, lineItems)

        await setCheckout(updatedCheckout)
      }
    } catch (error: any) {
      return error
    }

    return []
  }

  function total(vendor?: string | null): number {
    if (checkout === undefined) {
      return 0
    }

    if (vendor) {
      const entry = checkout.vendors.find((entry) => entry.brand.identifier === vendor)
      if (entry === undefined) {
        return 0
      }
      return lineItemsTotal(entry.lineItems)
    }

    return checkout.vendors.reduce((total, entry) => {
      return total + lineItemsTotal(entry.lineItems)
    }, 0)
  }

  function lineItemsTotal(lineItems: entity.checkout.LineItem[]): number {
    return lineItems.reduce((total, item) => {
      const totalDiscount = entity.checkout.getTotalDiscount(item)
      return total + item.quantity * item.variant.price.amount - totalDiscount
    }, 0)
  }

  async function updateAttributes(attributes: { key: string; value: string }[]) {
    if (!ready) {
      console.error("can't useCart.update if local storage is not ready ")
      return []
    }

    try {
      if (checkout === undefined) {
        console.error("can't useCart.update if no cart was created")
        return []
      } else {
        store.set(WAS_CART_TOUCHED_ATOM, true)

        const updatedCheckout = await core.checkout.client.updateAttributes(checkout.id, attributes)

        await setCheckout(updatedCheckout)
      }
    } catch (error: any) {
      return error
    }

    return []
  }
  async function applyDiscountCodeToCheckout(discountCode: string) {
    if (!ready) {
      console.error("can't useCart.update if local storage is not ready ")
      return []
    }

    try {
      if (checkout === undefined) {
        console.error("can't useCart.update if no cart was created")
        return []
      } else {
        store.set(WAS_CART_TOUCHED_ATOM, true)

        const updatedCheckout = await core.checkout.client.applyDiscountCodeToCheckout(checkout.id, discountCode)

        await setCheckout(updatedCheckout)
      }
    } catch (error: any) {
      return error
    }

    return []
  }
}

export function useCartItemCount(): number {
  const cart = useCart()

  if (!cart.ready || cart.empty) {
    return 0
  }

  return cart.data.vendors.reduce((itemCount, vendor) => {
    return vendor.lineItems.reduce((itemCount, lineItem) => itemCount + lineItem.quantity, itemCount)
  }, 0)
}

function useCheckout(): [
  { checkout: entity.checkout.Checkout | undefined; ready: boolean },
  (checkout: entity.checkout.Checkout) => Promise<entity.checkout.Checkout>,
] {
  const [checkout, setCheckout] = jotai.useAtom(CHECKOUT_ATOM)
  const [isReady, setIsReady] = jotai.useAtom(IS_CHECKOUT_READY)
  const dispatchMessage = services.message.useDispatch()

  const store = jotai.useStore()

  React.useEffect(() => {
    const isCheckoutSync = store.get(IS_CHECKOUT_SYNC_ATOM)
    if (!isCheckoutSync) {
      restoreCheckout()
    }
  }, [])

  React.useEffect(() => {
    if (checkout?.completedAt) {
      localStorage.removeItem(CHECKOUT_ID_LOCAL_STORAGE_KEY)
      setCheckout(jotaiUtils.RESET)
    }
  }, [checkout])

  const stableSetCheckout = React.useCallback(async (checkout: entity.checkout.Checkout) => {
    const cleanCheckout = await removeOfflineProducts(checkout)
    setCheckout(cleanCheckout)
    localStorage.setItem(CHECKOUT_ID_LOCAL_STORAGE_KEY, cleanCheckout.id)
    return cleanCheckout
  }, [])

  return [{ checkout, ready: isReady }, stableSetCheckout]

  async function restoreCheckout() {
    store.set(IS_CHECKOUT_SYNC_ATOM, true)

    const checkoutId = localStorage.getItem(CHECKOUT_ID_LOCAL_STORAGE_KEY)

    if (checkoutId !== null) {
      const result = await core.checkout.client.get(checkoutId)
      if (result !== null) {
        stableSetCheckout(result)
      } else {
        dispatchMessage(
          services.message.error('Une erreur est survenue avec votre panier', {
            context: 'boutique/cart',
          }),
        )
        const fallbackEmptyCheckout = await core.checkout.client.create([])

        stableSetCheckout(fallbackEmptyCheckout)
      }
    }

    setIsReady(true)
  }

  async function removeOfflineProducts(checkout: entity.checkout.Checkout): Promise<entity.checkout.Checkout> {
    const offlineLineItems = checkout.vendors.flatMap((vendor) => {
      return vendor.lineItems.filter((lineItem) => {
        return lineItem.variant.product.offline
      })
    })

    if (offlineLineItems.length !== 0) {
      dispatchMessage(
        services.message.alert(
          'Votre panier à été mis à jour car un ou plusieurs articles sont désormais indisponibles.',
          {
            context: 'boutique/cart',
          },
        ),
      )

      return await core.checkout.client.removeLineItems(checkout.id, offlineLineItems)
    }

    return checkout
  }
}
