import * as React from 'react'
import { cancelable } from '@owl-nest/cancelable-promise'

export enum AwaitStatus {
  LOADING,
  SUCCESS,
  FAILURE,
}

type State<SUCCESS> =
  | {
      status: AwaitStatus.LOADING
    }
  | {
      status: AwaitStatus.SUCCESS
      success: SUCCESS
    }
  | {
      status: AwaitStatus.FAILURE
      failure: any
    }

export function useAwait<SUCCESS>(promiseToAwait: Promise<SUCCESS>): State<SUCCESS> {
  const [state, setState] = React.useState<State<SUCCESS>>({
    status: AwaitStatus.LOADING,
  })

  React.useEffect(() => {
    const { cancel, promise } = cancelable(promiseToAwait)
    promise.then(
      (success) => setState({ status: AwaitStatus.SUCCESS, success }),
      (failure) => !failure.isCanceled && setState({ status: AwaitStatus.FAILURE, failure }),
    )
    return cancel
  }, [promiseToAwait])

  return state
}
