import styled, { css } from 'styled-components'

import * as color from '../constants/colors'

import { Tooltip } from '../components/Tooltip'

export type SocialShareListProps = {
  column?: boolean
}

export const Picto = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 38px;
  justify-content: center;
  margin: 4px;
  position: relative;
  width: 38px;

  &:nth-child(1) {
    color: ${color.PRIMARY_BLACK_HOVER};
    opacity: 0.6;
  }

  ${Tooltip} {
    min-width: fit-content;
    white-space: nowrap;
  }
`

export const SocialShare = styled.ul<SocialShareListProps>`
  align-items: center;
  background-color: ${color.PRIMARY_WHITE};
  border-radius: 2px;
  display: inline-flex;
  justify-content: space-around;
  position: relative;

  ${({ column }) =>
    column
      ? css`
          flex-direction: column;

          padding: 18px 0px;

          width: 50px;
        `
      : css`
          height: 50px;

          flex-direction: row;

          padding: 0px 18px;
        `}
`
