import * as React from 'react'
import * as jotai from 'jotai'
import * as jotaiUtils from 'jotai/utils'

import * as hooks from '@owl-nest/hooks'

import { clear } from './actions'
import { SYNC, Message, MessageState, MessageAction } from './types'
import { reducer, INITIAL_STATE } from './reducer'

export type MessageCRUD = {
  clearMessages: (context?: string) => void
}

export const MESSAGES_ATOM = jotaiUtils.atomWithReducer(INITIAL_STATE, reducer)

export function useDispatch(
  reduxDispatch?: hooks.Dispatch<MessageState, MessageAction>,
): hooks.Dispatch<MessageState, MessageAction> {
  // subscribe reduxDispatcher to jotai store to keep redux in sync
  const store = jotai.useStore()
  React.useEffect(() => {
    if (reduxDispatch !== undefined) {
      return store.sub(MESSAGES_ATOM, () => {
        reduxDispatch({ type: SYNC, state: store.get(MESSAGES_ATOM) })
      })
    }
  }, [])

  return hooks.useDispatchAtom(MESSAGES_ATOM)
}

export function useMessage(
  contexts?: string[],
  reduxDispatch?: hooks.Dispatch<MessageState, MessageAction>,
): { messages: Message[] } & MessageCRUD {
  // subscribe reduxDispatcher to jotai store to keep redux in sync
  const store = jotai.useStore()
  React.useEffect(() => {
    if (reduxDispatch !== undefined) {
      return store.sub(MESSAGES_ATOM, () => {
        reduxDispatch({ type: SYNC, state: store.get(MESSAGES_ATOM) })
      })
    }
  }, [])

  const dispatch = hooks.useDispatchAtom(MESSAGES_ATOM)

  const contextMessagesSharedValue = React.useMemo(
    () =>
      jotai.atom((get) => {
        const messages = get(MESSAGES_ATOM)
        if (contexts !== undefined) {
          return messages
            .filter((message) => !!message)
            .filter((message) => !message.context || contexts.includes(message.context))
        }
        return []
      }),
    [contexts],
  )

  const messages = jotai.useAtomValue(contextMessagesSharedValue)

  function clearMessages(context?: string): void {
    dispatch(clear(context))
  }

  return { messages, clearMessages }
}
