import { CLEAR, SYNC, MessageState, MESSAGE, MessageAction } from './types'

export { type Message, type MessageState as Messages } from './types'

export const INITIAL_STATE = []

export function reducer(messages: MessageState = INITIAL_STATE, action: MessageAction): MessageState {
  switch (action.type) {
    case MESSAGE: {
      if (action.options.focus === true) {
        window.scroll(0, 0)
      }
      const { level, message, options } = action
      const context = options.context || String(Math.random())
      return [...messages, { context, extra: options.extra, level, message }]
    }
    case CLEAR: {
      const context = action.context
      if (context !== undefined) {
        // only clear messages for this context
        return messages.filter((message) => !!message).filter((message) => message.context !== context)
      }
      // clear all messages
      return []
    }
    case SYNC: {
      return action.state
    }
    default: {
      return messages
    }
  }
}
