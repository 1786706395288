export const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || ''

export const SITE_URL = process.env.NEXT_PUBLIC_SITE_DOMAIN
  ? `https://${process.env.NEXT_PUBLIC_SITE_DOMAIN}`
  : 'http://localhost:3000'

export const SITE_NAME = 'Ulule Boutique'
export const SITE_TWITTER_HANDLE = process.env.NEXT_PUBLIC_SITE_TWITTER_HANDLE || ''

export const SUPPORT_MAIL_TO = 'support@ulule.com'

export const SVG_DIR = '/assets/svg/'
