/* eslint-disable react/display-name */

import * as React from 'react'

import * as richtext from '@owl-nest/rich-text'

import * as plume from '@ulule/owl-kit-components/next'

import { Hyperlink as BaseHyperlink } from './Hyperlink'

export type RichTextProps<ENTRY extends { sys: { id: string } }> = richtext.RichTextProps<ENTRY>

export function RichText<ENTRY extends { sys: { id: string } }>({
  document,
  links,
  renderEntry,
  renderMark: {
    Bold = plume.styles.wysiwyg.Bold,
    Italic = plume.styles.wysiwyg.Italic,
    Underline = plume.styles.wysiwyg.Underline,
  } = {},
  renderNode: {
    Paragraph = plume.styles.wysiwyg.Paragraph,
    Title1 = plume.styles.wysiwyg.Title,
    UnorderedList = plume.styles.wysiwyg.UnorderedList,
    OrderedList = plume.styles.wysiwyg.OrderedList,
    ListItem = plume.styles.wysiwyg.ListItem,
    BlockQuote = plume.styles.wysiwyg.BlockQuote,
    Hyperlink = BaseHyperlink,
    EmbeddedAsset = undefined,
  } = {},
}: RichTextProps<ENTRY>): React.ReactElement<RichTextProps<ENTRY>> {
  return (
    <richtext.RichText
      document={document}
      links={links}
      renderEntry={renderEntry}
      renderMark={{ Bold, Italic, Underline }}
      renderNode={{
        Paragraph,
        Title1,
        Title2: Title1,
        Title3: Title1,
        Title4: Title1,
        Title5: Title1,
        Title6: Title1,
        UnorderedList,
        OrderedList,
        ListItem,
        BlockQuote,
        Hyperlink,
        EmbeddedAsset,
      }}
    />
  )
}
