import * as cold from '@owl-nest/cold-data/client'
import * as entities from '@boutique/entities'
import * as url from '@boutique/url'
import { useQueryParams } from '@boutique/hooks'

export function useHierarchyHref(slug?: string): string {
  const { query, href } = useQueryParams(
    url.products({}).path,
    entities.search.product.fromParsedUrlQuery,
    entities.search.product.toUrlSearchParams,
  )

  if (slug === undefined) {
    const nextQuery: entities.search.product.Query = {
      ...query,
      facetFilters: {
        ...query.facetFilters,
        category: undefined,
      },
      hierarchy: undefined,
      page: undefined,
    }
    return href(nextQuery)
  }

  const isSlugCategory = cold.types['category'].includes(slug)

  const nextQuery: entities.search.product.Query = { facetFilters: {} }
  if (isSlugCategory) {
    nextQuery.facetFilters!.category = slug
  } else {
    nextQuery.hierarchy = slug
  }

  return href(nextQuery)
}
