import * as React from 'react'
import styled from 'styled-components'

type XmasIconComponentProps = {
  className?: string
}

function XmasIconComponent({ className }: XmasIconComponentProps): React.ReactElement {
  return (
    <svg
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.73181 14.121C3.1909 14.121 2.75216 13.6854 2.75216 13.1482V7.46762H5.65961V14.121H3.73181ZM1.86694 13.1482C1.86694 14.171 2.7021 15 3.73181 15H6.10222C6.34667 15 6.54484 14.8032 6.54484 14.5605V7.0281C6.54484 6.78536 6.34667 6.58858 6.10222 6.58858H2.30955C2.06511 6.58858 1.86694 6.78536 1.86694 7.0281V13.1482Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45532 14.5605C8.45532 14.8032 8.65163 15 8.8938 15H11.2418C11.7317 15 12.2016 14.8049 12.548 14.4577C12.8947 14.1103 13.0893 13.6393 13.0893 13.1482V7.0281C13.0893 6.78536 12.893 6.58858 12.6508 6.58858H8.8938C8.65163 6.58858 8.45532 6.78536 8.45532 7.0281V14.5605ZM9.33228 14.121V7.46762H12.2123V13.1482C12.2123 13.4061 12.1102 13.6535 11.9279 13.8361C11.746 14.0185 11.4992 14.121 11.2418 14.121H9.33228Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.32874 6.59134C2.19515 6.59134 2.06739 6.53871 1.97323 6.44499L1.97279 6.44456C1.87846 6.35093 1.8255 6.22395 1.8255 6.09123V5.15324C1.8255 5.02052 1.87846 4.89355 1.97279 4.79992L1.97323 4.79948C2.06739 4.70577 2.19515 4.65313 2.32874 4.65313H5.66346V6.59134H2.32874ZM1.34991 7.06396C1.6096 7.32231 1.9617 7.46704 2.32874 7.46704H6.10404C6.34737 7.46704 6.54463 7.27101 6.54463 7.02919V4.21529C6.54463 3.97347 6.34737 3.77744 6.10404 3.77744H2.32874C1.96163 3.77744 1.60948 3.92221 1.34977 4.18065C1.08992 4.43873 0.944336 4.78856 0.944336 5.15324V6.09123C0.944336 6.45598 1.08997 6.80587 1.34991 7.06396Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45532 7.02919C8.45532 7.27101 8.65258 7.46704 8.89591 7.46704H12.6712C13.0382 7.46704 13.3902 7.32236 13.6499 7.06408C13.9099 6.80597 14.0556 6.45603 14.0556 6.0912V5.15324C14.0556 4.78843 13.9099 4.43847 13.6499 4.18036C13.6499 4.18034 13.6498 4.18032 13.6498 4.1803C13.3902 3.92208 13.0381 3.77744 12.6712 3.77744H8.89591C8.65258 3.77744 8.45532 3.97347 8.45532 4.21529V7.02919ZM9.33649 6.59134V4.65313H12.6712C12.8048 4.65313 12.9326 4.70577 13.0267 4.79948L13.0272 4.79992C13.1215 4.89354 13.1744 5.02052 13.1744 5.15324V6.0912C13.1744 6.22396 13.1215 6.35094 13.0272 6.44456L13.0267 6.44499C12.9326 6.53871 12.8048 6.59134 12.6712 6.59134H9.33649Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64404 7.02919C5.64404 7.27101 5.84124 7.46704 6.0845 7.46704H8.91515C9.15841 7.46704 9.35561 7.27101 9.35561 7.02919V4.21529C9.35561 3.97347 9.15841 3.77744 8.91515 3.77744H6.0845C5.84124 3.77744 5.64404 3.97347 5.64404 4.21529V7.02919ZM6.52495 6.59134V4.65313H8.4747V6.59134H6.52495Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.61115 3.75713C4.80836 3.75713 4.15744 3.11231 4.15744 2.31705C4.15744 1.52178 4.80836 0.876969 5.61115 0.876969C6.41397 0.876969 7.06489 1.52179 7.06489 2.31705V3.75713H5.61115ZM3.27222 2.31705C3.27222 3.5966 4.31945 4.63403 5.61115 4.63403H7.5075C7.75195 4.63403 7.95011 4.43773 7.95011 4.19558V2.31705C7.95011 1.0375 6.90287 6.53267e-05 5.61115 6.53267e-05C4.31945 6.53267e-05 3.27222 1.0375 3.27222 2.31705Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.0498 4.19558C7.0498 4.43773 7.24611 4.63403 7.48826 4.63403H9.3668C10.6464 4.63403 11.6838 3.5966 11.6838 2.31705C11.6838 1.0375 10.6464 6.53267e-05 9.3668 6.53267e-05C8.08697 6.53267e-05 7.0498 1.03753 7.0498 2.31705V4.19558ZM7.92671 3.75713V2.31705C7.92671 1.52176 8.57134 0.876969 9.3668 0.876969C10.1621 0.876969 10.8069 1.52178 10.8069 2.31705C10.8069 3.11231 10.1621 3.75713 9.3668 3.75713H7.92671Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64404 14.5605C5.64404 14.8032 5.84126 15 6.08454 15H8.91512C9.15839 15 9.35561 14.8032 9.35561 14.5605V7.0281C9.35561 6.78536 9.15839 6.58858 8.91512 6.58858H6.08454C5.84126 6.58858 5.64404 6.78536 5.64404 7.0281V14.5605ZM6.52503 14.121V7.46762H8.47462V14.121H6.52503Z"
      />
    </svg>
  )
}

export const XmasIcon = styled(XmasIconComponent)``
