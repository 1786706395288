import * as logger from '@owl-nest/logger'
import * as utils from '@owl-nest/utils'
import { Query, rawRequester, GraphQLClient, ClientError, Client, Variables } from '@owl-nest/graphql'
import * as api from '@owl-nest/api-client/latest.ts'
import { Either } from '@owl-nest/monad'

const MAX_RETRIES = 5

export type Response<RESULT> = Promise<api.ApiResponse<RESULT>>

export type Request = <RESULT = any, VARIABLES extends Variables | undefined = Record<string, any>>(
  query: Query<RESULT, VARIABLES>,
  variables: VARIABLES,
  allowRetry?: boolean,
) => Response<RESULT>

export function requester(client: Client): Request {
  return request

  async function request<RESULT = any, VARIABLES extends Variables | undefined = Record<string, any>>(
    query: Query<RESULT, VARIABLES>,
    variables: VARIABLES,
    allowRetry = utils.browser.IS_IN_BROWSER,
    retry = 0,
  ): Response<RESULT> {
    try {
      return Either.right(await client.request(query, variables, allowRetry))
    } catch (error) {
      if (!(error instanceof ClientError)) {
        throw error
      }

      const response = error.response

      // Exponential backoff
      if (response.status === 200 && allowRetry && retry < MAX_RETRIES) {
        const wait = Math.floor(2 ** retry + Math.random() * 200 + 500)
        await new Promise((res, rej) => {
          setTimeout(res, wait)
        })
        return request(query, variables, allowRetry, retry + 1)
      }

      let message
      if (response.errors?.length) {
        message = ` "${response.errors[0].message}"`
      }

      logger.err(`[shopify] Request error - Code ${response.status}${message}`, {
        request: { query, variables },
        error,
      })

      return Either.left({
        status: response.status,
        url: query,
        body: error,
      })
    }
  }
}

const SHOPIFY_BASE_URL = process.env.NEXT_PUBLIC_SHOPIFY_URL
const SHOPIFY_API_VERSION = process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION

export const request = requester(
  rawRequester(
    new GraphQLClient(`${SHOPIFY_BASE_URL}/api/${SHOPIFY_API_VERSION}/graphql.json`, {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_SHOPIFY_TOKEN || '',
      },
    }),
  ),
)
