import * as React from 'react'

import * as jotai from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'
import type * as next from 'next/app'
import { DefaultSeo } from 'next-seo'
import { createGlobalStyle } from 'styled-components'

import { type NextRouter } from '@owl-nest/hooks'
import * as services from '@owl-nest/services'
import { SuspendedLocationInitializer } from '@owl-nest/shared-next'
import * as shadow from '@owl-nest/shadow'

import * as config from '@boutique/config'
import { BasePageProps, Layout } from '@boutique/layout'

const defaultSeo = {
  defaultTitle: config.SITE_NAME,
  openGraph: {
    locale: 'fr_FR',
    site_name: config.SITE_NAME,
    type: 'website',
  },
  facebook: { appId: config.FACEBOOK_APP_ID },
  twitter: {
    site: config.SITE_TWITTER_HANDLE,
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '48x48',
      href: config.SITE_URL + '/assets/images/favicon-48.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: config.SITE_URL + '/assets/images/favicon-32.png',
    },
    {
      rel: 'apple-touch-icon',
      href: config.SITE_URL + '/assets/images/apple-touch-icon-114x114.png',
      sizes: '114x114',
    },
    {
      rel: 'apple-touch-icon-precomposed',
      href: config.SITE_URL + '/assets/images/apple-touch-icon-114x114-precomposed.png',
      sizes: '114x114',
    },
  ],
}

export default function App(appProps: next.AppProps<BasePageProps>): React.ReactElement {
  useHydrateAtoms([[services.footer.FOOTER_CACHE, appProps.pageProps.footerCache]])

  const router = appProps.router as NextRouter

  return (
    <jotai.Provider>
      <shadow.TrackingProvider
        siteCategory={shadow.SITE.ULULE}
        pageCategory={shadow.CATEGORY.BOUTIQUE}
        userLocale={router.locale ?? router.defaultLocale}
        gtmContainerId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || ''}
      >
        <GlobalStyle />
        <DefaultSeo {...defaultSeo} />
        <SuspendedLocationInitializer router={appProps.router as NextRouter}>
          <InternalApp {...appProps} />
        </SuspendedLocationInitializer>
      </shadow.TrackingProvider>
    </jotai.Provider>
  )
}

function InternalApp(appProps: next.AppProps<BasePageProps>): React.ReactElement {
  const PageLayout = (appProps.Component as any).Layout ?? Layout

  return (
    <PageLayout props={appProps.pageProps}>
      <appProps.Component {...appProps.pageProps} />
    </PageLayout>
  )
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: sofia-pro, sans-serif;
    margin: 0;
    padding-top: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

`
