import { Shipping } from '@owl-nest/api-client/latest'
import { t } from '@owl-nest/localize'
import * as money from '@owl-nest/money'
import * as entity from '@boutique/entities'

import { delivery as deliveryModel } from '@owl-nest/models'
import { ShippingMethods } from '@boutique/entities/src/shipping'

type ShippingOption = {
  country: string
  currency: string
  label: string
  shipping: number
  value: string
}

// TODO: Test
export function countryIsSupported(countryCode: string, shippingOptions: deliveryModel.DeliveryShipping[]): boolean {
  return shippingOptions.findIndex((shippingOption) => shippingOption.country.value === countryCode) > -1
}

// TODO: Test
/**
 * Formats a given list of shipping entries towards a Select friendly format
 *
 * @param shippingOptions - List of shipping entries to format
 * @param currencySymbol - Human readable symbol for the cart currency
 * @param includePriceSuffix - Whether to append price information to the country label
 */
export const formatShippingOptionsForSelect = (
  shippingOptions: deliveryModel.DeliveryShipping[],
  currencySymbol: string,
  includePriceSuffix = true,
): ShippingOption[] => {
  return shippingOptions.map(({ country, shipping }) => ({
    country: country.content,
    currency: currencySymbol,
    label: includePriceSuffix ? getShippingOptionLabel(country.content, shipping, currencySymbol) : country.content,
    shipping,
    value: country.value,
  }))
}

export function findShippingForCountry(
  shippingOptions: deliveryModel.DeliveryShipping[],
  countryCode: string,
): deliveryModel.DeliveryShipping | undefined {
  return shippingOptions.find((shippingOption) => shippingOption.country.value === countryCode)
}

/**
 * Maps country codes to their shipping amount for a given list of shippings
 *
 * @param shippings - List of shipping to map data from
 * @returns A map of country codes and shipping amounts
 */
export function mapCountryToShipping(shippings: Array<Shipping> | undefined): Record<string, number> {
  // TODO: Probably clearer to throw
  if (!shippings) return {}

  return shippings.reduce((accumulator: Record<string, number>, shipping: Shipping): Record<string, number> => {
    const countries = shipping.countries?.reduce(
      (countryAccumulator: Record<string, number>, country: string) => ({
        ...countryAccumulator,
        [country]: shipping.amount,
      }),
      {},
    )
    return { ...accumulator, ...countries }
  }, {})
}

// Formats the given label to be displayed in the countries Select component
function getShippingOptionLabel(label: string, shipping: number, currencySymbol: string): string {
  const freeShipping = shipping === 0 || shipping === undefined
  return freeShipping
    ? t('%(label)s (included)', { label })
    : `${label} (+\xa0${t('%(currency)s%(amount)s', { amount: shipping, currency: currencySymbol })})`
}

export function getEstimatedTimeOfArrivalLabel(type: entity.shipping.ShippingType): string | undefined {
  if (type === ShippingMethods.Normal) {
    return 'Livraison à domicile sous 3 à 8 jours ouvrés.'
  } else if (type === ShippingMethods.Express) {
    return 'Livraison à domicile sous 1 à 2 jours ouvrés.'
  } else if (type === ShippingMethods.Premium) {
    return 'Livraison à domicile sous 1 à 2 jours ouvrés avec signature.'
  } else if (type === ShippingMethods.MondialRelay) {
    return 'Livraison en point relais Mondial Relay sous 3 à 6 jours ouvrés.'
  }
}

export function getDeliveryWarningMessage(deliveryExtra: string | null, productionDays: number | null): string | null {
  if (deliveryExtra) return deliveryExtra

  if (productionDays)
    return `Ce produit nécessite un délai de préparation de ${productionDays} ${productionDays > 1 ? 'jours' : 'jour'}.`

  return null
}

export function getFreeShippingThresholdMessage({
  remainingAmountBeforeFreeShipping,
  shippingCountry,
  brandName = '',
}: {
  remainingAmountBeforeFreeShipping: number
  shippingCountry?: string
  brandName?: string
}): string {
  let freeShippingObtained = `Vous bénéficiez de la livraison standard gratuite chez`
  freeShippingObtained += brandName ? ` ${brandName}` : ``

  let freeShippingYetToBeObtained = `Plus que ${money.format(
    remainingAmountBeforeFreeShipping,
  )} pour bénéficier de la livraison standard gratuite `
  freeShippingYetToBeObtained += shippingCountry ? `(${shippingCountry}) chez` : `chez`
  freeShippingYetToBeObtained += brandName ? ` ${brandName}` : ``

  return remainingAmountBeforeFreeShipping > 0 ? freeShippingYetToBeObtained : freeShippingObtained
}

export function getRemainingAmountBeforeFreeShipping(
  freeShippingThreshold: number | null | undefined,
  amount: number,
): number {
  if (freeShippingThreshold === null || freeShippingThreshold === undefined) {
    return Infinity
  }

  return Math.max(0, freeShippingThreshold - amount)
}
