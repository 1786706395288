import { UseDiscount } from './type'
import { useFreeProductOverThreshold, Config } from './useFreeProductOverThreshold'

const RULE_CONFIG = getRuleConfig()

export function useDiscount(): UseDiscount {
  return useFreeProductOverThreshold(RULE_CONFIG)
}

function getRuleConfig(): Config | undefined {
  const ruleString = process.env.NEXT_PUBLIC_CART_RULE
  if (ruleString !== undefined) {
    return JSON.parse(ruleString)
  }
}
