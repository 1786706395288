import * as graphql from 'graphql-request'
import * as logger from '@owl-nest/logger'
import { type Query } from './query.ts'

export type Request = <RESULT = any, VARIABLES extends graphql.Variables | undefined = graphql.Variables>(
  query: Query<RESULT, VARIABLES>,
  variables: VARIABLES,
  allowRetry?: boolean,
) => Promise<RESULT>

export type Client = { request: Request }

export function rawRequester(client: graphql.GraphQLClient): Client {
  return { request }

  async function request<RESULT = any, VARIABLES extends graphql.Variables | undefined = graphql.Variables>(
    query: Query<RESULT, VARIABLES>,
    variables: VARIABLES,
  ): Promise<RESULT> {
    let data

    try {
      data = await client.request(query, variables)
    } catch (payload) {
      if (!payload) {
        logger.err(`[graphql] Unknown request error`, {
          data,
          request: { query, variables },
        })
      }
      throw payload
    }

    return data as RESULT
  }
}
