import * as React from 'react'
import styled, { css } from 'styled-components'

import * as url from '@boutique/url'
import * as plume from '@ulule/owl-kit-components/next'

type EmptyCartComponentProps = {
  inPopup?: boolean
}

function EmptyCartComponent({ inPopup }: EmptyCartComponentProps): React.ReactElement {
  return (
    <EmptyCartWrapper inPopup={inPopup}>
      <plume.illustrations.boutique.Cart size={140} />
      <plume.styles.heading.M asDesktop="L">Panier vide</plume.styles.heading.M>
      <plume.styles.copy.M asDesktop="L">
        Votre panier est vide, explorez notre magasin pour le remplir de bons produits.
      </plume.styles.copy.M>
      <plume.ButtonAsLink href={url.products({}).path} color="blue" size="small">
        Découvrir nos produits
      </plume.ButtonAsLink>
    </EmptyCartWrapper>
  )
}

export const EmptyCartItem = styled(EmptyCartComponent)``

const EmptyCartWrapper = styled.div<{ inPopup?: boolean }>`
  margin: 80px auto 90px;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 4px solid #d9f8f6; /* HACK: Replace with COLORS.SECONDARY_MINT_300 */

  ${plume.illustrations.boutique.Cart} {
    width: 131px;
    height: 100px;
    margin-top: -60px;
    background-color: ${plume.COLORS.PRIMARY_WHITE};
    padding: 12px 24px;
  }

  ${plume.styles.heading.M} {
    order: 0;
    padding-top: 24px;
    padding-bottom: 16px;
    font-weight: 600;
  }

  ${plume.ButtonAsLink} {
    align-items: center;
    margin-top: 32px;
    margin-bottom: 48px;
  }

  ${plume.styles.copy.M} {
    text-align: center;
    max-width: 250px;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin: 70px auto 32px;

    ${plume.illustrations.boutique.Cart} {
      margin-top: -80px;

      ${({ inPopup }) => {
        if (inPopup) {
          return css`
            width: 79px;
            height: 77px;
            padding: 0;
            margin: -32px 0 24px;
          `
        } else {
          return css`
            width: 181px;
            height: 134px;
            padding: 12px 32px;
          `
        }
      }}
    }

    ${plume.styles.heading.M} {
      padding-top: 32px;
    }

    ${plume.ButtonAsLink} {
      font-size: 17px;
      margin-top: 48px;
    }

    ${plume.styles.copy.M} {
      ${({ inPopup }) =>
        inPopup &&
        css`
          ${plume.styles.copy._mediumStyle}
          padding: 0 16px;
        `}

      max-width: 405px;
      font-weight: 600;
    }
  }

  ${({ inPopup }) => {
    if (inPopup) {
      return css`
        border: none;
        margin: 80px 0 0;

        ${plume.styles.heading.M} {
          display: none;
        }

        ${plume.ButtonAsLink} ${plume.styles.button.Button} {
          font-size: 17px; // HACK: illegal font manipulation
        }
      `
    }
  }}
`
