import * as React from 'react'
import styled from 'styled-components'

import * as copy from '../../styles/copy'
import * as heading from '../../styles/heading'
import * as notification from '../../styles/notification'

import { Base, type BaseProps } from './Base'

export type CalloutProps = BaseProps & {
  title?: string
  wrapWithCopy?: boolean
}

function CalloutComponent({
  children,
  className,
  customIcon,
  title,
  type,
  wrapWithCopy = true,
}: CalloutProps): React.ReactElement<CalloutProps> {
  return (
    <Base className={className} customIcon={customIcon} type={type}>
      <notification.CalloutContentWrapper>
        {title && <heading.XXXS>{title}</heading.XXXS>}
        {wrapWithCopy ? <copy.S>{children}</copy.S> : children}
      </notification.CalloutContentWrapper>
    </Base>
  )
}

export const Callout = styled(CalloutComponent)<CalloutProps>``
Callout.displayName = 'Callout'
