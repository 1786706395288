import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../constants/colors'
import * as S from '../../styles'
import { FooterColor } from '../../styles/cartItem/cartItemFooter'

export type CartCardProps = {
  footer?: { color: FooterColor; content: React.ReactNode }
  className?: string
  logo: {
    alt: string | null
    src: string
  }
  title: string
  total: string
  children: React.ReactNode
}

function CartCardComponent({ className, children, logo, title, footer, total }: CartCardProps): React.ReactElement {
  return (
    <CartCardWrapper className={className}>
      <S.cartItemHeader.Wrapper>
        <S.cartItemHeader.Picture src={logo.src} alt={logo.alt ?? title} />
        <S.cartItemHeader.Title asDesktop="S">{title}</S.cartItemHeader.Title>
      </S.cartItemHeader.Wrapper>

      {children}

      <S.cartItemFooter.Wrapper>
        <S.cartItemFooter.Total>Total vendeur</S.cartItemFooter.Total>
        <S.cartItemFooter.TotalAmount>{total}</S.cartItemFooter.TotalAmount>
      </S.cartItemFooter.Wrapper>

      {footer && (
        <S.cartItemFooter.Banner color={footer.color}>
          <S.cartItemFooter.BannerText>{footer.content}</S.cartItemFooter.BannerText>
        </S.cartItemFooter.Banner>
      )}
    </CartCardWrapper>
  )
}

export const CartCardWrapper = styled.div`
  max-width: 624px;
  border: 1px solid ${colors.GREY_SHADE_5};
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  overflow: hidden;

  ${S.cartItemFooter.Banner} {
    margin-top: 24px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
  }
`

export const CartCard = styled(CartCardComponent)``

type CartItemSkeletonProps = {
  className?: string
}

function CartCardSkeletonComponent({ className }: CartItemSkeletonProps): React.ReactElement {
  return (
    <CartCardWrapper className={className}>
      <S.cartItemHeader.Wrapper>
        <S.skeleton.Circle radius={60} />
        <S.skeleton.Text size="medium" width={60} />
      </S.cartItemHeader.Wrapper>

      <S.cartItemHeader.Wrapper></S.cartItemHeader.Wrapper>

      <S.cartItemBody.Wrapper>
        <S.skeleton.Rectangle height={120} />
        <S.skeleton.Text size="medium" width={60} />
        <S.quantityInput.Controls>
          <S.skeleton.Circle radius={34} />
          <S.skeleton.Text size="medium" width={20} />
          <S.skeleton.Circle radius={34} />
        </S.quantityInput.Controls>
        <S.skeleton.Text size="medium" width={10} />
      </S.cartItemBody.Wrapper>

      <S.cartItemFooter.Wrapper>
        <S.skeleton.Text width={20} />
        <S.skeleton.Text width={20} />
      </S.cartItemFooter.Wrapper>
    </CartCardWrapper>
  )
}

export const CartCardSkeleton = styled(CartCardSkeletonComponent)`
  ${S.cartItemHeader.Wrapper} {
    ${S.skeleton.Circle} {
      margin-right: 20px;
    }
  }
  ${S.cartItemBody.Wrapper} {
    ${S.skeleton.Text}:last-child {
      margin-left: auto;
    }
  }

  ${S.skeleton.Rectangle} {
    width: 120px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
`
