import * as React from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'
import * as cold from '@owl-nest/cold-data'
import * as richtext from '@boutique/rich-text'

const coldNotification = cold.notification.boutique

export function NotificationBanner(): React.ReactElement | null {
  const router = useRouter()

  if (
    router.pathname.includes('/about') ||
    router.pathname.includes('/account') ||
    router.pathname.includes('/faq') ||
    router.pathname.includes('/404') ||
    router.pathname.includes('/500')
  ) {
    return null
  }

  if (!coldNotification?.notification) return null

  return (
    <Banner type="alert" closable={false} customIcon={() => <div />} wrapWithCopy={false}>
      <richtext.RichText
        document={coldNotification.notification.json}
        renderNode={{ Paragraph: plume.styles.copy.XS }}
      />
    </Banner>
  )
}

const Banner = styled(plume.Banner)`
  position: relative;
  padding: 8px 36px;
  min-height: auto;

  ${plume.styles.wysiwyg.Hyperlink} {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
`
