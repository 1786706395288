import styled from 'styled-components'
import * as breakpoint from '../constants/breakpoints'
import { MAIN_CONTAINER_WIDTH } from '../constants/sizes'

export const SkewedContainer = styled.div<{ skewedBorder: 'bottom' | 'top' }>`
  margin-top: -20px;
  padding: 35px 0 60px;
  transform-origin: top left;
  transform: skew(0, -5deg);
`
SkewedContainer.displayName = 'S.container.SkewedContainer'
// WARNING: the risk is that on some browsers the content can be blurred
// an alternative is to use ::before / ::after to generate a triangle

export const SkewedContent = styled.div`
  transform: skew(0, 5deg);
`
SkewedContent.displayName = 'S.container.SkewedContent'

export const Base = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-width: 320px;

  @media screen and ${breakpoint.MOBILE_L} {
    max-width: ${MAIN_CONTAINER_WIDTH};
    padding-left: 40px;
    padding-right: 40px;
  }
`
