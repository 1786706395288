import * as React from 'react'
import styled from 'styled-components'
import PlausibleProvider from 'next-plausible'

import * as entities from '@owl-nest/entities'

import * as plume from '@ulule/owl-kit-components/next'
import * as header from '@ulule/header'

import { useUTMCatcher } from '@boutique/utm'
import { PageViewProvider, getPageViewManager, usePageView } from '@boutique/shadow'
import * as cart from '@boutique/cart'

import { AppHeader } from './Header'
import { AppFooter } from './Footer'
import { NotificationBanner } from './NotificationBanner'
import { BoutiqueSubnav } from './BoutiqueSubnav'

type LayoutProps = {
  children: React.ReactNode
  props?: any
}

function InnerLayout({ children, props }: LayoutProps): React.ReactElement<unknown> {
  useUTMCatcher()

  usePageView()

  // This is kind of a hack. The layout is a component commont to multiple
  // pages. In this layout, we want display the `BoutiqueSubnav` component that
  // needs to know which category is currently displayed. For the product
  // listing pages it's easy, the information is directly in the url. But for
  // the detail page, we only have the slug and id of a product in the url. But
  // the `product` entity is passed to the page component as a props and it
  // contains the category. So we need to dig inside the props to know if there
  // is a `product`, and use it to know the current category
  let category: string | undefined = undefined
  if (hasProduct(props)) {
    category = props.product.category?.slug
  }

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)

  return (
    <App>
      <PlausibleProvider manualPageviews domain={process.env.NEXT_PUBLIC_SITE_DOMAIN!}>
        <plume.GlobalStyleDesignSystem />
        <AppHeader isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
        <Main>
          <Sticky>
            <BoutiqueSubnav
              category={category}
              cart={<cart.PopupCart />}
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
            />
          </Sticky>
          <NotificationBanner />
          {children}
        </Main>
        <AppFooter />
      </PlausibleProvider>
    </App>
  )
}

function hasProduct(props: any): props is { product: entities.product.MediumProduct } {
  return props && props.product
}

const pageViewManager = getPageViewManager()

export function Layout(props: LayoutProps): React.ReactElement {
  return (
    <PageViewProvider manager={pageViewManager}>
      <InnerLayout {...props} />
    </PageViewProvider>
  )
}

export const App = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Main = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${plume.Breadcrumbs} {
    margin-top: 24px;
  }
`

const Sticky = styled.div`
  display: none;
  left: 0;
  position: sticky;
  top: 0;
  z-index: ${plume.ZINDEX.HEADER - 1};

  @media screen and ${header.styles.INTERMEDIATE_BREAKPOINT} {
    display: flex;
  }
`
