import * as React from 'react'
import styled from 'styled-components'
import * as glyphs from '../icons/glyphs'

import * as S from '../styles'

type Crumb = {
  label: string
  path: string
}

export type BreadcrumbsProps = {
  crumbs: Crumb[]
  LinkComponent: React.ComponentType<{ href: string; children: React.ReactNode }>
} & React.HTMLAttributes<HTMLDivElement>

function BreadcrumbsComponent({ crumbs, LinkComponent, ...navProps }: BreadcrumbsProps): React.ReactElement {
  return (
    <nav {...navProps} aria-label="Fil d'Ariane">
      <S.breadcrumbs.List>
        {crumbs.map((crumb, index) => {
          if (index !== crumbs.length - 1) {
            return (
              <S.breadcrumbs.Crumb key={crumb.path}>
                <LinkComponent href={crumb.path}>{crumb.label}</LinkComponent>
                <glyphs.stroke.CaretRightLightweight role="presentation" size={8} />
              </S.breadcrumbs.Crumb>
            )
          }

          return (
            <S.breadcrumbs.Crumb key={index}>
              <span>{crumb.label}</span>
            </S.breadcrumbs.Crumb>
          )
        })}
      </S.breadcrumbs.List>
    </nav>
  )
}

export const Breadcrumbs = styled(BreadcrumbsComponent)``

type BreadcrumbsSkeletonProps = {
  className?: string
  width?: number
}

function BreadcrumbsSkeletonComponent({ className, width = 20 }: BreadcrumbsSkeletonProps) {
  return (
    <SkeletonWrapper className={className}>
      <S.skeleton.Text size="small" width={width} />
    </SkeletonWrapper>
  )
}

export const BreadcrumbsSkeleton = styled(BreadcrumbsSkeletonComponent)``

const SkeletonWrapper = styled.div`
  width: 100%;
`
