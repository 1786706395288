import * as React from 'react'

import * as layout from '@owl-nest/layout'
import * as services from '@owl-nest/services'
import * as hooks from '@owl-nest/hooks'
import * as contentful from '@boutique/contentful'

export function AppFooter(): React.ReactElement {
  // TODO: set conf for 4 columns in Contentful once everything validated
  //const { response } = services.footer.useFooterCollection({ params: { locale: 'fr' }, request: contentful.request })
  //const navSections = convertResponseToNavSections(response)

  const navSections = [
    {
      title: 'Ulule Boutique',
      items: [
        {
          label: 'Contacter Ulule Boutique',
          href: 'https://support.ulule.com/hc/fr-fr/requests/new?ticket_form_id=14675711147793',
          openInNewTab: true,
        },
        {
          label: 'Livraisons et retours',
          href: 'https://boutique.ulule.com/livraison-retours',
        },
        {
          label: 'Idées cadeaux',
          href: 'https://boutique.ulule.com/idees-cadeaux',
        },
        {
          label: 'Carte cadeau',
          href: 'https://boutique.ulule.com/produit/carte-cadeau-ulule-boutique-7070601576631',
        },
        {
          label: 'Sélection du moment',
          href: 'https://boutique.ulule.com/produits',
        },
        {
          label: 'Bonnes affaires',
          href: 'https://boutique.ulule.com/produits?hasCompareAtPrice=true',
        },
        {
          label: 'Vendre sur Ulule Boutique',
          href: 'https://outils.ulule.com/articles/vendre-sur-ulule-boutique',
          openInNewTab: true,
        },
        {
          label: 'CGV',
          href: 'https://boutique.ulule.com/cgv',
        },
      ],
    },
    {
      title: 'Nos marques',
      items: [
        {
          label: 'Circles',
          href: `https://boutique.ulule.com/produits?brands=circles`,
        },
        {
          label: 'Hindbag',
          href: 'https://boutique.ulule.com/produits?brands=hindbag',
        },
        {
          label: 'Oopla',
          href: 'https://boutique.ulule.com/produits?brands=oopla',
        },
        {
          label: 'Minuit sur Terre',
          href: 'https://boutique.ulule.com/produits?brands=minuit-sur-terre',
        },
        {
          label: 'CAMIF',
          href: 'https://boutique.ulule.com/produits?brands=camif',
        },
        {
          label: 'Ateliers en entreprise',
          href: 'https://partenaires.ulule.com/formations-entreprise',
        },
        {
          label: 'Corail',
          href: 'https://boutique.ulule.com/produits?brands=corail',
        },
        {
          label: 'Si Si La Paillette',
          href: 'https://boutique.ulule.com/produits?brands=si-si-la-paillette',
        },
        {
          label: 'Angarde',
          href: 'https://boutique.ulule.com/produits?brands=angarde',
        },
        {
          label: 'Pachamama',
          href: 'https://boutique.ulule.com/produits?brands=pachamama',
        },
      ],
    },
    {
      title: 'Liens utiles',
      items: [
        {
          label: 'Liste des marques',
          href: 'https://boutique.ulule.com/marques',
        },
        {
          label: 'Newsletter Bien ou Bien',
          href: 'https://www.bienoubien.com/infolettre',
          openInNewTab: true,
        },
        {
          label: 'Guide des marques engagées',
          href: 'https://www.bienoubien.com/',
          openInNewTab: true,
        },
        {
          label: 'Guide mode responsable',
          href: 'https://www.bienoubien.com/guide/guide-de-la-mode',
          openInNewTab: true,
        },
        {
          label: 'Actu conso engagée',
          href: 'https://www.bienoubien.com/magazine',
          openInNewTab: true,
        },
        {
          label: 'Guide cosmétique',
          href: 'https://www.bienoubien.com/guide/guide-de-la-cosmetique',
          openInNewTab: true,
        },
        {
          label: 'Guide alimentation responsable',
          href: 'https://www.bienoubien.com/guide/guide-de-lalimentation',
          openInNewTab: true,
        },
        {
          label: 'Guide maison durable',
          href: 'https://www.bienoubien.com/guide/guide-de-la-maison',
          openInNewTab: true,
        },
        {
          label: 'Noël durable',
          href: 'https://www.bienoubien.com/magazine/noel-durable-conseils-idees',
          openInNewTab: true,
        },
        {
          label: "Cadeaux d'entreprise",
          href: 'https://dreamact-pro.eu/',
          openInNewTab: true,
        },
      ],
    },
    {
      title: 'À propos d’Ulule',
      items: [
        {
          label: 'Découvrir Ulule',
          href: 'https://fr.ulule.com/pages/discover/',
        },
        {
          label: 'Nous contacter',
          href: `https://fr.ulule.com/about/contact/`,
        },
        {
          label: 'Visite vidéo',
          href: `https://fr.ulule.com/pages/visite`,
        },
        {
          label: 'Devenir partenaire',
          href: 'https://partenaires.ulule.com/',
        },
        {
          label: 'Organiser un appel à projets',
          href: 'https://partenaires.ulule.com/organiser-appel-projets',
        },
        {
          label: 'API',
          href: 'https://developers.ulule.com/',
        },
        {
          label: 'Équipe',
          href: `https://fr.ulule.com/about/team/`,
        },
        {
          label: 'Presse',
          href: `https://fr.ulule.com/about/press/`,
        },
        {
          label: 'Newsletter',
          href: `https://fr.ulule.com/newsletter/`,
        },
      ],
    },
  ]

  return <layout.Footer lang="fr" navSections={navSections} hasCurrencyAndLang={false} />
}

function convertResponseToNavSections(
  response: hooks.Response<services.footer.FooterCollectionResult>,
): layout.NavSection[] {
  if (response?.data?.footerCollection?.items === undefined) {
    return []
  }
  const items = response.data.footerCollection.items[0]

  return [
    {
      title: items.leftColumnTitle,
      items: items.leftColumnLinksCollection?.items ?? [],
    },
    {
      title: items.centerColumnTitle,
      items: items.centerColumnLinksCollection?.items ?? [],
    },
    {
      title: items.rightColumnTitle,
      items: items.rightColumnLinksCollection?.items ?? [],
    },
  ]
}
