import styled from 'styled-components'

import * as colors from '../constants/colors'
import { Trash } from '../icons/glyphs/stroke/index'
import * as copy from './copy'

export type ButtonQuantityProps = {
  active?: boolean
}

export const ButtonQuantity = styled.button`
  background-color: transparent;
  border-radius: 100%;
  border: 1px solid ${colors.GREY_SHADE_7};
  color: ${colors.GREY_SHADE_7};
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 0px;
  padding: 0px;
  position: relative;
  text-align: center;
  transition: 0.3s ease;

  &:hover {
    border: 1px solid ${colors.GREY_SHADE_2};
    color: ${colors.GREY_SHADE_2};
    outline: none;
  }

  &:focus,
  &:active {
    border: 1px solid ${colors.GREY_SHADE_2};
    color: ${colors.GREY_SHADE_2};
    outline: none;
  }
`

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;

  ${Trash} {
    color: ${colors.GREY_SHADE_7};
    display: inline-block;
    fill-rule: evenodd;
    height: 1em;
    vertical-align: middle;
    width: 1em;

    &:hover {
      color: ${colors.GREY_SHADE_2};
    }
  }
`

export const QuantityWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  padding-top: 16px;
`

export const Quantity = styled(copy.L)`
  font-weight: 600; // HACK: Irregular font manipulation. We'll be adding copy.L.semiBold, so this is just by anticipation.
`

export const UnstyledButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  text-align: left;
  cursor: pointer;

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`
