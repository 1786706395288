import styled from 'styled-components'

import * as heading from './heading'
import * as color from '../constants/colors'
import * as glyphs from '../icons/glyphs'

export const Crumb = styled(heading.XXXXXS).attrs({ as: 'li' })`
  display: inline-flex;
  align-items: end;
  text-transform: uppercase;
  color: ${color.PRIMARY_BLACK};

  ${glyphs.stroke.CaretRightLightweight} {
    margin: 1px 4px;
    padding: 0.5px 0;
    user-select: none;
  }
`

export const List = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Separator = styled.span`
  margin: 0 3px;
`
