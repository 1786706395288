import * as React from 'react'

import { request, ApiResponse } from '@owl-nest/api-client'
import { useAwait, Status } from '@owl-nest/hooks'

export type GeolocationFix = {
  city: string
  country_code: string
  country_name: string
  ip: string
  latitude: number
  longitude: number
  metro_code: number
  region_code: string
  region_name: string
  time_zone: string
  zip_code: string
}

const GEOLOCATION_SERVICE = 'https://geo.ulule.com/json/'

export type Config = {
  force?: boolean
}

let RESULT_MEMOIZATION: Promise<ApiResponse<GeolocationFix>> | undefined = undefined

export async function getFix(config: Config = {}): Promise<ApiResponse<GeolocationFix>> {
  if (RESULT_MEMOIZATION === undefined || config.force === true) {
    RESULT_MEMOIZATION = getPureFix()
  }

  return RESULT_MEMOIZATION
}

async function getPureFix(): Promise<ApiResponse<GeolocationFix>> {
  const result = await request<GeolocationFix>(GEOLOCATION_SERVICE)
  return result.next((response) => response.body)
}

export function useFix():
  | { status: Status.LOADING }
  | { status: Status.SUCCESS; value: GeolocationFix }
  | { status: Status.FAILURE; value: undefined } {
  // memoize the promise before useAwait to always have the same promise on each
  // render and avoid a render loop (useAwait awaiting on a new promise on each
  // render, and updating an internal state for the status of the promise)
  const geolocatedCountryCode = React.useMemo(async () => {
    const response = await getFix()
    return response.caseOf({
      right: (success) => success,
      left: () => undefined,
    })
  }, [])

  const awaitedGeolocatedCountryCode = useAwait(geolocatedCountryCode)

  return React.useMemo(() => {
    switch (awaitedGeolocatedCountryCode.status) {
      case Status.LOADING:
        return { status: Status.LOADING }
      case Status.SUCCESS:
        if (awaitedGeolocatedCountryCode.success) {
          return { status: Status.SUCCESS, value: awaitedGeolocatedCountryCode.success }
        }
      // intentionnal fallthrough. Succes with no value is the same as failure
      // eslint-disable-next-line no-fallthrough
      default:
        return { status: Status.FAILURE, value: undefined }
    }
  }, [awaitedGeolocatedCountryCode.status])
}
