import { t } from '@owl-nest/localize'

import { Level, MESSAGE, CLEAR, CreateActionOptions, CreateAction, ClearAction } from './types'

function create(level: Level, message: string, options: CreateActionOptions): CreateAction {
  return { type: MESSAGE, level, message, options }
}

export function alert(message: string, options: CreateActionOptions = {}): CreateAction {
  return create('alert', message, options)
}

export function clear(context?: string): ClearAction {
  return { type: CLEAR, context }
}

export function error(message: string, options: CreateActionOptions = {}): CreateAction {
  return create('error', message, options)
}

export function info(message: string, options: CreateActionOptions = {}): CreateAction {
  return create('info', message, options)
}

export function internalError({ focus = true, context = 'internal_error' }: CreateActionOptions = {}): CreateAction {
  return alert(t('Oops, an internal error has occurred... Please retry!'), { focus, context })
}

// TODO: FIXME: Crashes
// export function notification(message: string, options: CreateActionOptions = {}): CreateAction {
//   return create('notification', message, options)
// }

export function success(message: string, options: CreateActionOptions = {}): CreateAction {
  return create('success', message, options)
}
