import * as React from 'react'
import styled from 'styled-components'

import { lightweight, standalone, SymbolProps } from '../../spritesheetUtils'
import { Icon } from '../Icon'
import { SpecificIconProps } from '../types'

const PLUS_PATH = (
  <g>
    <path d="M16 4a2.167 2.167 0 0 0-2.167 2.167v8.666H5.167a2.167 2.167 0 0 0 0 4.334h8.666v8.666a2.167 2.167 0 1 0 4.334 0v-8.666h8.666a2.167 2.167 0 1 0 0-4.334h-8.666V6.167A2.167 2.167 0 0 0 16 4Z" />
  </g>
)

const ID = 'plus-stroke-glyph-icon'

export function PlusSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id}>
      {PLUS_PATH}
    </symbol>
  )
}

const PlusStandaloneContent = standalone(PlusSymbol, ID)

export const Plus = styled(function PlusComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="plus">
      <PlusStandaloneContent />
    </Icon>
  )
})``

export const PlusLightweight = styled(function PlusComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="plus-light-weight">
      {lightweight(ID)}
    </Icon>
  )
})``
