import styled from 'styled-components'

import * as colors from '../../constants/colors'
import * as breakpoints from '../../constants/breakpoints'
import * as copy from '../copy'
import * as heading from '../heading'

export const Total = styled(heading.XS)`
  color: ${colors.PRIMARY_GREY_900};
`

export const TotalAmount = styled(heading.XS)`
  /*  FIXME : this color is currently not in the DS */
  color: #2e8d87; /* HACK: Replace with COLORS.SECONDARY_MINT_700 */
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: space-between;
  padding-top: 10px;
  max-width: 624px;
`

const COLORS = {
  yellow: colors.SECONDARY_YELLOW_200,
}

export type FooterColor = keyof typeof COLORS

export const Banner = styled.div<{ color: FooterColor }>`
  background-color: ${({ color }) => COLORS[color]};
`

export const BannerText = styled(copy.S)`
  padding: 8px 16px;
  text-align: center;

  @media screen and ${breakpoints.TABLET} {
    padding: 6px 0px 10px;
  }
`
