import * as React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

import * as money from '@owl-nest/money'
import * as shopify from '@owl-nest/shopify'
import * as entity from '@boutique/entities'
import * as url from '@boutique/url'
import * as bobUrl from '@bob/url'
import * as utils from '@boutique/utils'

import { useDiscount } from '../hooks/useDiscount'
import { Discount } from './Discount'
import { useCart } from '../hooks/useCart'
import { EmptyCartItem } from './EmptyCartItem'

type ModalState = { open: false } | { open: true; resolve: () => void; reject: () => void }

type CartItemListProps = {
  freeShippingThresholds?: entity.shipping.FreeShippingThresholds
  shippingCountry?: string
  inPopup?: boolean
}

export function CartItemList({
  freeShippingThresholds,
  shippingCountry,
  inPopup,
}: CartItemListProps): React.ReactElement {
  const cart = useCart()
  const [modalState, setModalState] = React.useState<ModalState>({
    open: false,
  })

  const [isBusy, busyDecorate] = useIsBusy()

  const discount = useDiscount()

  if (!cart.ready) {
    return <plume.CartCardSkeleton />
  }

  if (!cart.ready || cart.empty) {
    return <EmptyCartItem inPopup={inPopup} />
  }

  return (
    <>
      {discount.isApplicable && (
        <Discount
          inPopup={inPopup}
          name={discount.name}
          src={discount.image}
          isBusy={isBusy}
          onAccept={busyDecorate(discount.accept)}
          onReject={busyDecorate(discount.reject)}
        />
      )}
      {cart.data.vendors.map(({ brand, lineItems }) => {
        const total = cart.total(brand.identifier)
        return (
          <plume.CartCard
            key={brand.identifier}
            logo={brand.logo}
            title={brand.name}
            total={money.format(total)}
            footer={footer(brand, freeShippingThresholds?.[brand.identifier], shippingCountry, total)}
          >
            {lineItems.map((item) => {
              const itemPrice = entity.checkout.getPrice(item)
              const totalDiscount = entity.checkout.getTotalDiscount(item)
              const discountedPrice = entity.checkout.getDiscountedPrice(item, itemPrice, totalDiscount)

              const deliveryExtra = item.variant.product.deliveryExtra?.value ?? null
              const productionDays = item.variant.product.productionDays?.value ?? null
              const deliveryWarningMessage = utils.shipping.getDeliveryWarningMessage(deliveryExtra, productionDays)

              return (
                <plume.CartItem
                  key={item.id}
                  disabled={isBusy}
                  deliveryWarningMessage={deliveryWarningMessage ?? undefined}
                  discountedPrice={discountedPrice ? money.formatDiscount(discountedPrice) : undefined}
                  href={
                    url.product({
                      id: shopify.utils.toNumericId(item.variant.product.id, 'Product'),
                      handle: item.variant.product.handle,
                      title: item.variant.product.title,
                    }).path
                  }
                  image={item.variant.image.src}
                  price={money.format(itemPrice)}
                  quantity={item.quantity}
                  subtitle={item.variant.title !== 'Default Title' ? item.variant.title : ''}
                  title={item.variant.product.title}
                  LinkComponent={({ href, children }) => <Link href={href}>{children}</Link>}
                  onIncrement={increment(item)}
                  onDecrement={decrement(item)}
                  onRemove={remove(item)}
                />
              )
            })}
          </plume.CartCard>
        )
      })}
      <RemoveModal
        open={modalState.open}
        closable
        onClose={modalDismiss}
        buttons={[
          {
            type: 'button',
            props: {
              size: 'medium',
              onClick: modalAccept,
              children: 'Oui, retirer',
            },
          },
          {
            type: 'linkAsButton',
            props: {
              kind: 'secondary',
              onClick: modalDismiss,
              children: 'Non, conserver',
            },
          },
        ]}
      >
        <plume.styles.heading.S>Retirer cet article de votre panier ?</plume.styles.heading.S>
      </RemoveModal>
    </>
  )

  function modalAccept(): void {
    if (modalState.open) {
      modalState.resolve() // continue the pending delete action
    }
    setModalState({ open: false })
  }

  function modalDismiss(): void {
    if (modalState.open) {
      modalState.reject() // cancel the pending delete action
    }
    setModalState({ open: false })
  }

  function increment(item: entity.checkout.LineItem) {
    if (!cart.ready || cart.empty) {
      return undefined
    }

    return busyDecorate(async () => {
      await cart.update([
        {
          ...item,
          quantity: item.quantity + 1,
        },
      ])
    })
  }

  function decrement(item: entity.checkout.LineItem) {
    if (!cart.ready || cart.empty) {
      return undefined
    }

    return busyDecorate(async () => {
      if (item.totalVariantQuantity === 1) {
        /* WARNING: This pattern is bad, and should not be used unless you
          have not other choice.

          It is considered an anti-pattern to have internal promise function
          (`resolve` and `reject`) outside the scope of the promise. This is an
          anti-pattern because it means internals of the promise can be passed
          everywhere in the code, leading to spaghetti code ("uh ho, i changed
          this one line there, and the building behind me collapsed") or tightly
          coupled code  (a method located elsewhere in the code can change
          internal state of this promise).

          In this case we need this pattern, because we need to be able to
          resolve/reject the promise outside its scope. The `onDecrease`
          method will disable all buttons on the `ds.CartItem` during the
          operation, so we need to have a promise that resolves only when the
          "decrementing" operation is done.
          If there is only one item, we want to display a modal for the user
          to confirm that the item should be deleted (and only delete the item
          after the user confirmation). This means that before trying to
          decrement, we have to await for the user to confirm.
          In order to do so, we need to bind a "resolve" method to a button in
          the modal. But the modal reside outside the scope of the `onDecrease`.
          So we need to use the "deferred" anti-pattern to "smuggle out" internal
          methods `resolve`/`reject` through a React state for them to be used
          inside the modal.
          */
        await new Promise<void>((resolve, reject) => {
          setModalState({
            open: true,
            resolve,
            reject,
          })
        })
        await cart.remove([item])
      } else {
        await cart.update([{ ...item, quantity: item.quantity - 1 }])
      }
    })
  }

  function remove(item: entity.checkout.LineItem) {
    if (!cart.ready || cart.empty) {
      return undefined
    }

    return busyDecorate(async () => {
      /** WARNING: This pattern is bad, and should not be used unless you
                               have not other choice. See the other warning for a complete explanation
                              */
      await new Promise<void>((resolve, reject) => {
        setModalState({
          open: true,
          resolve,
          reject,
        })
      })
      if (item.totalVariantQuantity === item.quantity) {
        await cart.remove([item])
      } else {
        await cart.update([{ ...item, quantity: 0 }])
      }
    })
  }
}

function useIsBusy(): [boolean, (fn: () => Promise<void>) => () => Promise<void>] {
  const [isBusy, setIsBusy] = React.useState(false)
  const isMountedRef = React.useRef(false)
  React.useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  return [isBusy, busyDecorate]

  function busyDecorate(fn: () => Promise<void>): () => Promise<void> {
    return async () => {
      if (isBusy) {
        return
      }

      setIsBusy(true)

      try {
        await fn()
      } catch (e) {
        // do nothing, errors means "action cancelation"
      } finally {
        // if fn is remove or decrement, the component might be unmounted. If so, we do not update the state
        isMountedRef.current && setIsBusy(false)
      }
    }
  }
}

export const RemoveModal = styled(plume.Modal)`
  ${plume.styles.modal.Body} {
    ${plume.styles.heading.S} {
      margin-bottom: 20px;
      flex-grow: 1;
    }

    ${plume.LinkAsButton} {
      font-size: 13px; // HACK: illegal font manipulation
    }

    @media screen and ${plume.BREAKPOINTS.TABLET} {
      ${plume.styles.heading.S} {
        text-align: center;
        margin-bottom: 20px;
        flex-grow: 0;
      }
    }
  }
`

function footer(
  brand: entity.brand.ColdBrand,
  freeShippingThreshold: number | undefined | null,
  shippingCountry: string | undefined,
  vendorTotal: number,
): plume.CartCardProps['footer'] {
  const remainingAmountBeforeFreeShipping = utils.shipping.getRemainingAmountBeforeFreeShipping(
    freeShippingThreshold,
    vendorTotal,
  )

  if (freeShippingThreshold === null || remainingAmountBeforeFreeShipping === Infinity) {
    return undefined
  }

  const message = utils.shipping.getFreeShippingThresholdMessage({
    remainingAmountBeforeFreeShipping,
    shippingCountry,
  })

  const href = bobUrl.brand({
    slug: brand.slug,
    name: brand.name,
  }).path

  return {
    color: 'yellow',
    content: (
      <>
        {message} <Link href={href}>{brand.name}</Link>
      </>
    ),
  }
}
