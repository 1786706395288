import * as React from 'react'
import * as jotai from 'jotai'

import { useFix } from '@owl-nest/geolocation'
import * as hooks from '@owl-nest/hooks'

import { useCartShippingRates } from './useShippingRates'
import { useCart } from './useCart'

export const SHIPPING_COUNTRY_ATOM = jotai.atom<string | undefined>('FR')

export function useShippingCountry(): [string | undefined, (shippingCountry: string | undefined) => void] {
  const [shippingCountry, setShippingCountry] = jotai.useAtom(SHIPPING_COUNTRY_ATOM)

  const fix = useFix()
  const cart = useCart()
  const geolocatedCountry = fix.status === hooks.AwaitStatus.SUCCESS ? fix.value.country_code : undefined

  const response = useCartShippingRates({ fetchOnMount: true })

  const shippingRatesData = hooks.data(response)
  const supportedCountries = shippingRatesData?.supportedCountries

  React.useEffect(() => {
    if (shippingCountry) {
      return
    }

    if (!supportedCountries || !geolocatedCountry) {
      return setShippingCountry(undefined)
    }

    if (supportedCountries.length > 0) {
      const index = supportedCountries.findIndex((country) => country === geolocatedCountry)
      if (index !== -1) {
        setShippingCountry(geolocatedCountry)
      }
    }
  }, [cart, geolocatedCountry, shippingCountry, supportedCountries])

  return [shippingCountry, setShippingCountry]
}
