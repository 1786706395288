import * as React from 'react'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import styled from 'styled-components'

import * as cold from '@owl-nest/cold-data/client'
import * as plume from '@ulule/owl-kit-components/next'

import { MenuItem, TopLevelEntry, Underlined } from './styles'

export function BoutiqueEntry({ forceCategory }: { forceCategory: string | undefined }): React.ReactElement {
  const router = useRouter()

  const isBoutiqueHomeActive = cold.hierarchy.tree.children.every((child) => {
    return !isCategoryActive(child)
  })

  return (
    <MenuItem as="div" firstItem>
      <StyledNextLink href="/">
        <TopLevelEntry as="div" $active={isBoutiqueHomeActive}>
          <Underlined>
            <plume.styles.heading.XS>Boutique</plume.styles.heading.XS>
          </Underlined>
        </TopLevelEntry>
      </StyledNextLink>
    </MenuItem>
  )

  function isCategoryActive(category: cold.SlimCold['hierarchy']['tree']): boolean {
    return (
      router.query['category'] === category.id ||
      cold.getTree(cold.hierarchy, router.query['hierarchy'] as string).parents[0]?.id === category.id ||
      (forceCategory !== undefined && cold.tags[forceCategory].parents.includes(category.id))
    )
  }
}

const StyledNextLink = styled(NextLink)`
  height: 100%;
  width: 100%;
`
